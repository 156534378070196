<template>
  <div
    class="feature-area"
    v-bind:class="{
      'pt-120': edit != 'true',
      'pb-120': edit != 'true',
      'pt-3': edit == 'true',
    }"
  >
    <div class="container">
      <div class="feature-wrapper">
        <div class="row">
          <div v-for="(d, k) in data" :key="k" class="col-md-6 col-lg-3 mb-3">
            <div class="feature-item" v-bind:class="{ active: k % 2 == 0 }">
              <div class="feature-icon"><i :class="d.icon"></i></div>
              <div class="feature-shadow-icon">
                <i :class="d.icon"></i>
              </div>
              <div class="feature-content">
                <h4
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editText1($event, k)"
                >
                  {{ d.text1 }}
                </h4>
                <p
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editText2($event, k)"
                >
                  {{ d.text2 }}
                </p>
                <span
                  style="color: white"
                  v-if="edit == 'true'"
                  v-on:blur="editIcon($event, k)"
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  >{{ d.icon }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["edit", "data"],
  methods: {
    editText1(event, key) {
      let t = this;
      t.data[key].text1 = event.target.innerText;
    },
    editText2(event, key) {
      let t = this;
      t.data[key].text2 = event.target.innerText;
    },
    editIcon(event, key) {
      let t = this;
      t.data[key].icon = event.target.innerText;
    },
    addObject() {
      let t = this;
      t.data.push({
        icon: "fas fa-thumbs-up",
        text1: "text1",
        text2: "text2",
      });
    },
  },
};
</script>
<style lang="less">
.feature-item {
  height: 100%;
}
</style>