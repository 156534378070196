<template>
  <div :key="data.blog">
    <input
      type="text"
      class="form-control"
      v-model="blockId"
      v-if="blockId && edit == 'true'"
    />
    <blog-view :id="data.blog" />
  </div>
</template>

<script>
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import BlogView from "@/components/Home/BlogView.vue";
export default {
  props: ["edit", "data"],
  data() {
    return {
      blockId: null,
    };
  },
  mounted() {
    this.blockId = this.data.blog;
  },
  watch: {
    blockId(val) {
      let t = this;
      t.data.blog = val;
    },
  },
  components: { BlogView },
  methods: {},
};
</script>
<style lang="less">
</style>