<template>
  <div class="service-area service-bg py-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="site-heading text-center">
            <span
              class="site-title-tagline"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText1($event, k)"
              >{{ data.text1 }}</span
            >
            <h2
              class="site-title"
              id="data-text2"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText2($event, k)"
            ></h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-md-6 col-lg-4"
          v-for="(d, k) in data.data"
          :key="k + d.img"
        >
          <div class="service-item">
            <div class="service-img" v-on:click="editImg(k)">
              <ImgThumb :src="d.img" width="380" height="255" />
            </div>
            <div class="service-icon"><i :class="d.icon"></i></div>
            <div class="service-content">
              <h3 class="service-title">
                <router-link v-if="edit != 'true'" :to="d.button.link">{{
                  d.text1
                }}</router-link>
                <span
                  v-if="edit == 'true'"
                  style="
                    font-size: 22px;
                    margin-top: 20px;
                    font-weight: 700;
                    display: inline-block;
                    margin-bottom: 10px;
                    width: 100%;
                    color: #263787;
                  "
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemText1($event, k)"
                >
                  {{ d.text1 }}
                </span>
              </h3>
              <p
                class="service-text"
                style="width: 100%"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editItemText2($event, k)"
              >
                {{ d.text2 }}
              </p>
              <div class="service-arrow" v-if="edit != 'true'">
                <router-link :to="d.button.link" class="theme-btn"
                  >{{ d.button.text }} <i class="far fa-arrow-right"></i
                ></router-link>
              </div>
              <div class="service-arrow" v-if="edit == 'true'">
                <div
                  :contenteditable="true"
                  v-on:blur="editButtonText($event, k)"
                >
                  {{ d.button.text }}
                </div>
                <div
                  :contenteditable="true"
                  v-on:blur="editButtonLink($event, k)"
                >
                  {{ d.button.link }}
                </div>
                <div :contenteditable="true" v-on:blur="editIcon($event, k)">
                  {{ d.icon }}
                </div>
                <div>
                  <i v-on:click="removeItem(k)" class="fas fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgThumb from "@/components/Shared/ImgThumb.vue";
import linhhm from "../../linhhm.bootstrap";
export default {
  data() {
    return {
      lhmIMG: null,
    };
  },
  props: ["edit", "data"],
  components: { ImgThumb },
  mounted() {
    if (this.edit) {
      this.lhmIMG = new linhhm.Image(false);
    }
    let text2 = this.$el.querySelector("#data-text2");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
  },
  methods: {
    editImg(key) {
      let t = this;
      if (this.edit == "true") {
        t.lhmIMG.setCallback((src) => {
          t.data.data[key].img = src;
        });
        t.lhmIMG.openDialog();
      }
    },
    editText1() {
      let t = this;

      let text1 = this.$el.querySelector("#about-us-text1-html");
      if (text1) {
        t.data.text1 = text1.innerHTML;
      }
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerHTML;
    },
    editItemText1(event, key) {
      let t = this;
      t.data.data[key].text1 = event.target.innerText;
    },
    editItemText2(event, key) {
      let t = this;
      t.data.data[key].text2 = event.target.innerText;
    },
    editButtonText(event, key) {
      let t = this;
      t.data.data[key].button.text = event.target.innerText;
    },
    editButtonLink(event, key) {
      let t = this;
      t.data.data[key].button.link = event.target.innerText;
    },
    editIcon(event, key) {
      let t = this;
      t.data.data[key].icon = event.target.innerText;
    },
    addObject() {
      let t = this;
      t.data.data.push({
        img: "/img/khoa-hoc/khoa-hoc-robotic.jpg",
        icon: "far fa-graduation-cap",
        text1: "Khóa học Lập trình, Robotic và AI",
        text2:
          "There are many variations of passages orem psum available but the majority have suffered alteration in some form by injected.",
        button: {
          text: "Đọc thêm",
          link: "#",
        },
      });
    },
    removeItem(key) {
      let t = this;
      if (typeof t.data.data[key] != "undefined") {
        t.data.data.splice(key, 1);
      }
    },
  },
};
</script>
<style lang="less" scope="this api replaced by slot-scope in 2.5.0+">
.service-item {
  .service-img {
    img {
      width: 100%;
    }
  }
}
.feature-item {
  height: 100%;
}
</style>