<template>
  <swiper
    :slides-per-view="1"
    :space-between="0"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :grabCursor="true"
    :effect="'creative'"
    :creativeEffect="{
      prev: {
        shadow: true,
        translate: ['-120%', 0, -500],
      },
      next: {
        shadow: true,
        translate: ['120%', 0, -500],
      },
    }"
    :modules="modules"
  >
    <swiper-slide v-for="(d, k) in data" :key="objId(d, k)">
      <div class="swiper-item" :style="slideStyle(d.img)">
        <div class="hero-single">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-11 col-md-7 col-lg-7">
                <div class="hero-content">
                  <h6
                    class="hero-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".25s"
                    :contenteditable="edit == 'true' ? 'true' : 'false'"
                    v-on:blur="editText1($event)"
                  >
                    {{ d.text1 }}
                  </h6>
                  <h1
                    class="hero-title wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".50s"
                    :contenteditable="edit == 'true' ? 'true' : 'false'"
                    v-html="d.text2"
                    v-on:blur="editText2($event)"
                  ></h1>
                  <p
                    class="wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".75s"
                    :contenteditable="edit == 'true' ? 'true' : 'false'"
                    v-on:blur="editText3($event)"
                  >
                    {{ d.text3 }}
                  </p>
                  <div
                    class="hero-btn wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <a :href="d.button1.link" class="theme-btn"
                      >{{ d.button1.text }}<i class="far fa-arrow-right"></i
                    ></a>
                    <span
                      v-if="d.button1 && edit == 'true'"
                      style="
                        border: 1px solid black;
                        padding: 0 30px;
                        line-height: 55px;
                        color: white;
                      "
                      :contenteditable="edit == 'true' ? 'true' : 'false'"
                      v-on:blur="editButtonText($event)"
                    >
                      {{ d.button1.text }}
                    </span>

                    <span
                      v-if="d.button1 && edit == 'true'"
                      style="
                        border: 1px solid black;
                        padding: 0 30px;
                        line-height: 55px;
                        color: white;
                      "
                      :contenteditable="edit == 'true' ? 'true' : 'false'"
                      v-on:blur="editButtonLink($event)"
                    >
                      {{ d.button1.link }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default {
  props: ["edit", "data"],
  data() {
    return {
      swiper: null,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    document.execCommand("styleWithCSS", 0, true);
  },
  methods: {
    boldText() {
      console.log("boldText");
      document.execCommand("bold");
    },
    objId(d, k) {
      return d.img + k;
    },
    editText1(event) {
      let t = this;
      console.log("blur");
      t.data[this.swiper.realIndex].text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data[this.swiper.realIndex].text2 = event.target.innerHTML;
    },
    editText3(event) {
      let t = this;
      t.data[this.swiper.realIndex].text3 = event.target.innerText;
    },
    editButtonText(event) {
      let t = this;
      t.data[this.swiper.realIndex].button1.text = event.target.innerText;
    },
    editButtonLink(event) {
      let t = this;
      t.data[this.swiper.realIndex].button1.link = event.target.innerText;
    },
    removeCurrentSlide() {
      let t = this;
      if (typeof t.data[t.swiper.realIndex] != "undefined") {
        t.data.splice(t.swiper.realIndex, 1);
      }
    },
    changeImage(src) {
      console.log(this.swiper.realIndex);
      let t = this;
      t.data[this.swiper.realIndex].img = src;
      console.log(src);
    },
    addObject() {
      let t = this;
      t.data.push({
        img: "/img/slider/slider-1.jpg",
        text1: "Text 1",
        text2: "Text 2 <span>text 2</span> text 2",
        text3: "Text 3",
        button1: {
          text: "About More",
          link: "#",
        },
        button2: {
          text: "Learn More",
          link: "#",
        },
      });
      console.log(this.swiper);
      if (this.swiper) {
        setTimeout(() => {
          t.swiper.slideTo(t.data.length - 1);
        }, 500);
      }
    },
    slideStyle(img) {
      return (
        "background: url('" +
        img +
        "') center center no-repeat;background-size: cover;"
      );
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
  setup() {
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSlideChange,
    };
  },
};
</script>
<style lang="less" scope="this api replaced by slot-scope in 2.5.0+">
.swiper-item {
  padding-top: 170%;
  position: relative;
  .hero-single {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: -0.5px;
      top: 0;
      background: linear-gradient(
        90deg,
        rgba(38, 55, 135, 1) 0%,
        rgba(38, 55, 135, 1) 2%,
        rgba(38, 55, 135, 0) 100%
      );
      z-index: -1;
    }
  }
}
@media (min-width: 768px) {
  .swiper-item {
    padding-top: 80%;
  }
}
@media (min-width: 992px) {
  .swiper-item {
    padding-top: 700px;
  }
}
</style>