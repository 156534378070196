<template>
  <div class="blog-item" :key="id" v-if="blog">
    <div class="blog-item-img">
      <router-link :to="blogLink()"
        ><ImgThumb :src="blog.avatar" width="380" height="380" alt="Thumb"
      /></router-link>
    </div>
    <div class="blog-item-info">
      <div class="blog-item-meta">
        <ul>
          <!-- <li>
            <a href="#"><i class="far fa-user-circle"></i> By Alicia Davis</a>
          </li> -->
          <li>
            <router-link :to="blogLink()"
              ><i class="far fa-calendar-alt"></i>
              {{ timeString(blog.lastUpdate) }}</router-link
            >
          </li>
        </ul>
      </div>
      <h4 class="blog-title">
        <router-link :to="blogLink()">{{ blog.name }}</router-link>
      </h4>
      <router-link :to="blogLink()" class="theme-btn"
        >Đọc thêm<i class="far fa-arrow-right"></i
      ></router-link>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
import ImgThumb from "@/components/Shared/ImgThumb.vue";
export default {
  props: ["id"],
  data() {
    return {};
  },
  components: { ImgThumb },
  computed: {
    blog() {
      return this.$store.getters.blog(this.id);
    },
  },
  mounted() {},
  methods: {
    blogLink() {
      if (this.blog) {
        return "/blog/" + this.blog.alias + "/" + this.blog._id;
      } else {
        return "/";
      }
    },
    timeString(time) {
      return new Date(time).toLocaleDateString();
    },
    async getBlog(id) {
      try {
        let res = await axios.get("/api/article/" + id);
        this.blog = res.data;
      } catch {
        console;
      }
    },
  },
  watch: {
    id(val) {
      if (val.length == 24) {
        this.getBlog(val);
      }
    },
  },
};
</script>