<template>
  <div class="blog-item" :key="id" v-if="blog">
    <div class="container">
      <div v-html="blog.description"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    blog() {
      let blog = this.$store.getters.blog(this.id);
      return blog;
    },
  },
  methods: {},
  watch: {},
};
</script>