<template>
  <div>
    <header class="header">
      <HeaderTop />
      <NavMenu />
    </header>

    <div class="search-popup">
      <button class="close-search"><span class="far fa-times"></span></button>
      <form action="#">
        <div class="form-group">
          <input type="search" name="search-field" placeholder="Search Here..." required />
          <button type="submit"><i class="far fa-search"></i></button>
        </div>
      </form>
    </div>
    <slot></slot>
    <footer class="footer-area">
      <div class="footer-widget">
        <div class="container">
          <div class="row footer-widget-wrapper pt-100 pb-70">
            <div class="col-md-6 col-lg-4">
              <div class="footer-widget-box about-us" style="text-align: left">
                <a href="#" class="footer-logo">
                  <img src="/assets/img/logo/logobw.png" alt="" />
                </a>
                <ul class="footer-contact">
                  <li>
                    <a href="tel:+84903987456"><i class="far fa-phone"></i>0903 987 456</a>
                  </li>
                  <li>
                    <i class="far fa-map-marker-alt"></i>M14 Khu dân cư Phú Nhuận, khu phố 1, Quận 12, Hồ Chí Minh, Việt Nam
                  </li>
                  <li>
                    <a href="mailto:support@azzura.com"><i class="far fa-envelope"></i>support@azzura.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-lg-2">
              <div class="footer-widget-box list">
                <h4 class="footer-widget-title">Về azzura</h4>
                <ul class="footer-list">
                  <li>
                    <router-link to="/gioi-thieu"><i class="fas fa-caret-right"></i> Giới thiệu</router-link>
                  </li>
                  <li>
                    <router-link to="/khoa-hoc/tieng-anh"><i class="fas fa-caret-right"></i>Khóa học tiếng anh</router-link>
                  </li>
                  <li>
                    <router-link to="/khoa-hoc/robot"><i class="fas fa-caret-right"></i>Khóa học lập trình, robot và AI</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="footer-widget-box list">
                <h4 class="footer-widget-title">Quy định và điều khoản</h4>
                <ul class="footer-list">
                  <li>
                    <a href="#"><i class="fas fa-caret-right"></i>Chính sách đại lý</a>
                  </li>
                  <li>
                    <a href="#"><i class="fas fa-caret-right"></i>Chính sách bảo mật</a>
                  </li>
                  <li>
                    <a href="#"><i class="fas fa-caret-right"></i>Chính sách thanh toán</a>
                  </li>
                  <li>
                    <a href="#"><i class="fas fa-caret-right"></i>Chính sách bảo hành</a>
                  </li>
                  <li>
                    <a href="#"><i class="fas fa-caret-right"></i>Quy trình thi công lắp đặt</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="footer-widget-box list">
                <h4 class="footer-widget-title">Tổng đài hỗ trợ</h4>
                <ul class="footer-list">
                  <li>
                    <a href="tel:0948027207"><i class="fas fa-caret-right"></i> Tư vấn đặt lịch: 0948 027 207</a>
                  </li>
                  <li>
                    <a href="tel:0948027207"><i class="fas fa-caret-right"></i> Bảo hành: 0948 027 207</a>
                  </li>
                  <li>
                    <a href="tel:0948027207"><i class="fas fa-caret-right"></i> Khiếu naị: 0948 027 207</a>
                  </li>
                  <li>
                    <a href="tel:0948027207"><i class="fas fa-caret-right"></i> Hợp tác đại lý: 0948 027 207</a>
                  </li>
                </ul>
              </div>
            </div>
            <!--<div class="col-md-6 col-lg-3">
              <div class="footer-widget-box list">
                <h4 class="footer-widget-title">Newsletter</h4>
                <div class="footer-newsletter">
                  <p>Subscribe Our Newsletter To Get Latest Update And News</p>
                  <div class="subscribe-form">
                    <form action="#">
                      <input type="email" class="form-control" placeholder="Your Email" />
                      <button class="theme-btn" type="submit">
                        Subscribe Now <i class="far fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6 align-self-center">
              <p class="copyright-text">
                &copy; Copyright <span id="date"></span><a href="#"> Azzura </a> All Rights Reserved.
              </p>
            </div>
            <div class="col-md-6 align-self-center">
              <ul class="footer-social">
                <li>
                  <a href="https://facebook.com/azzuravietnam" target="__blank"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import HeaderTop from "@/components/Shared/HeaderTop.vue";
import NavMenu from "@/components/Shared/NavMenu.vue";

export default {
  components: { HeaderTop, NavMenu },
};
</script>