<template>
  <div class="pricing-area py-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="site-heading text-center">
            <span
              class="site-title-tagline"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText1($event)"
            >
              {{ data.text1 }}
            </span>
            <h2
              class="site-title"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText2($event)"
            ></h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div class="row mt-20">
        <div class="col-md-6 col-lg-3" v-for="(d, k) in data.data" :key="k">
          <div class="pricing-item">
            <div class="pricing-header">
              <div class="pricing-icon">
                <i :class="d.icon"></i>
              </div>
              <div class="pricing-header-content">
                <h4
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemText1($event, k)"
                >
                  {{ d.text1 }}
                </h4>
                <p
                  class="pricing-duration"
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemText2($event, k)"
                >
                  {{ d.text2 }}
                </p>
                <h1
                  class="pricing-amount"
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemText3($event, k)"
                >
                  {{ d.text3 }}
                </h1>
              </div>
            </div>
            <div class="pricing-feature">
              <ul>
                <li
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemList1($event, k)"
                >
                  {{ d.list1 }}
                </li>
                <li
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemList2($event, k)"
                >
                  {{ d.list2 }}
                </li>
                <li
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemList3($event, k)"
                >
                  {{ d.list3 }}
                </li>
                <li
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemList4($event, k)"
                >
                  {{ d.list4 }}
                </li>
              </ul>
            </div>
            <div
              v-if="edit == 'true'"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editItemIcon($event, k)"
            >
              {{ d.icon }}
            </div>

            <div v-if="edit == 'true'">
              <i v-on:click="removeObject(k)" class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["edit", "data"],
  mounted() {
    let text2 = this.$el.querySelector(".site-title");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
  },
  methods: {
    editItemIcon(event, key) {
      let t = this;
      t.data.data[key].icon = event.target.innerText;
    },
    editItemText1(event, key) {
      let t = this;
      t.data.data[key].text1 = event.target.innerText;
    },
    editItemText2(event, key) {
      let t = this;
      t.data.data[key].text2 = event.target.innerText;
    },
    editItemText3(event, key) {
      let t = this;
      t.data.data[key].text3 = event.target.innerText;
    },
    editItemList1(event, key) {
      let t = this;
      t.data.data[key].list1 = event.target.innerText;
    },
    editItemList2(event, key) {
      let t = this;
      t.data.data[key].list2 = event.target.innerText;
    },
    editItemList3(event, key) {
      let t = this;
      t.data.data[key].list3 = event.target.innerText;
    },
    editItemList4(event, key) {
      let t = this;
      t.data.data[key].list4 = event.target.innerText;
    },
    editText1(event) {
      let t = this;
      t.data.text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerText;
    },
    addObject() {
      let t = this;
      t.data.data.push({
        icon: "icon-sedan-car-model",
        text1: "Xe Sedan",
        text2: "Hoàn thành: 30phút",
        text3: "1.500.000đ",
        list1: "Thảm trước",
        list2: "Thảm sau",
        list3: "Cốp",
        list4: "Rối",
      });
    },
    removeObject(key) {
      let t = this;
      if (typeof t.data.data[key] != "undefined") {
        t.data.data.splice(key, 1);
      }
    },
  },
};
</script>