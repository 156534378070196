<template>
  <PublicView>
    <div class="home">
      <main class="main">
        <div v-for="m in modules" :key="m._id">
          <SwiperSlide v-if="m.type == 0" :data="m.data" />
          <FeatureArea v-if="m.type == 1" :data="m.data" />
          <AreaAboutUs v-if="m.type == 2" :data="m.data" />
          <AreaService v-if="m.type == 3" :data="m" />
          <AreaCounter v-if="m.type == 4" :data="m.data" />
          <AreaPortfolio v-if="m.type == 5" :data="m" />
          <AreaPricing v-if="m.type == 6" :data="m" />
          <AreaTeam v-if="m.type == 7" :data="m" />
          <AreaFaq v-if="m.type == 8" :data="m" />
          <AreaTestimonial v-if="m.type == 9" :data="m" />
          <AreaBlog v-if="m.type == 10" :data="m" />
          <AreaArticle v-if="m.type == 11" :data="m" />
          <AreaProduct v-if="m.type == 12" :data="m" />
        </div>
        <BlogArea />
      </main>
    </div>
  </PublicView>
</template>
<script>
const axios = require("axios").default;
import SwiperSlide from "@/components/Shared/SwiperSlider.vue";
import FeatureArea from "@/components/Shared/FeatureArea.vue";
import AreaAboutUs from "@/components/Shared/AreaAboutUs.vue";
import AreaService from "@/components/Shared/AreaService.vue";
import AreaCounter from "@/components/Shared/AreaCounter.vue";
import AreaPortfolio from "@/components/Shared/AreaPortfolio.vue";
import AreaPricing from "@/components/Shared/AreaPricing.vue";
import AreaProduct from "@/components/Shared/AreaProduct.vue";
import AreaTeam from "@/components/Shared/AreaTeam.vue";
import AreaFaq from "@/components/Shared/AreaFaq.vue";
import AreaBlog from "@/components/Shared/AreaBlog.vue";
import AreaTestimonial from "@/components/Shared/AreaTestimonial.vue";
import AreaArticle from "@/components/Shared/AreaArticle.vue";
import PublicView from "@/components/PublicView.vue";
export default {
  name: "HomeView",
  data() {
    return {
      modules: [],
    };
  },
  mounted() {
    document.querySelector("title").innerText =
      "Azzura.vn - hệ thống bán trú chất lượng cao";
    this.getModules();
  },
  methods: {
    async getModules() {
      try {
        let res = await axios.get("/api/modules/home");
        this.modules = res.data;
        console.log(res);
      } catch {
        console;
      }
    },
  },
  components: {
    SwiperSlide,
    FeatureArea,
    AreaAboutUs,
    AreaService,
    AreaCounter,
    AreaPortfolio,
    AreaPricing,
    AreaTeam,
    AreaFaq,
    AreaTestimonial,
    AreaBlog,
    AreaArticle,
    PublicView, AreaProduct
  },
};
</script>
