<template>
  <div class="portfolio-area bg py-120">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="site-heading text-center">
            <span
              class="site-title-tagline"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText1($event, k)"
              >{{ data.text1 }}</span
            >
            <h2
              class="site-title"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText2($event, k)"
            ></h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div
        class="portfolio-slider owl-carousel owl-theme popup-gallery owl-loaded owl-drag"
      >
        <swiper
          :breakpoints="breakpoints"
          :autoplay="true"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(d, k) in data.data" :key="objId(d, k)">
            <ImgThumb
              :src="d.img"
              width="380"
              height="380"
              v-on:click="editImg(k)"
            />
            <i
              v-if="edit == 'true'"
              class="fas fa-trash-alt"
              v-on:click="removeSlide(k)"
            ></i>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ImgThumb from "@/components/Shared/ImgThumb.vue";
import Swal from "sweetalert2";
import linhhm from "../../linhhm.bootstrap";
export default {
  props: ["edit", "data"],
  data() {
    return {
      lhmIMG: null,
      breakpoints: {
        0: { slidesPerView: 1, spaceBetween: 30 },
        480: { slidesPerView: 2, spaceBetween: 30 },
        768: { slidesPerView: 3, spaceBetween: 30 },
        992: { slidesPerView: 4, spaceBetween: 30 },
      },
      swiper: null,
      sidesPerView: 4,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ImgThumb,
  },
  mounted() {
    let t = this;
    if (t.edit) {
      t.lhmIMG = new linhhm.Image(false);
    }
    let text2 = this.$el.querySelector(".site-title");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
    console.log(t.$el);
    /* this.sidesPerView = Math.ceil(this.$el.innerWidth() / 300);
    window.addEventListener("resize", function () {
      t.sidesPerView = Math.ceil(t.$el.innerWidth() / 300);
    }); */
  },
  watch: {
    sidesPerView(val) {
      console.log(val);
    },
  },
  methods: {
    objId(d, k) {
      return d.img + k;
    },
    editText1(event) {
      let t = this;
      t.data.text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerHTML;
    },
    removeSlide(key) {
      let t = this;
      Swal.fire({
        title: "Xác nhận xóa",
        showCancelButton: true,
        confirmButtonText: "Xóa",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (typeof t.data.data[key] != "undefined") {
            t.data.data.splice(key, 1);
          }
        }
      });
    },

    editImg(key) {
      let t = this;
      if (this.edit == "true") {
        t.lhmIMG.setCallback((src) => {
          t.data.data[key].img = src;
        });
        t.lhmIMG.openDialog();
      }
    },
    addObject() {
      let t = this;
      t.data.data.push({
        img: "/assets/img/portfolio/01.jpg",
      });
    },
    onSwiper(swiper) {
      console.log(swiper);
      this.swiper = swiper;
    },
  },
  setup() {
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSlideChange,
    };
  },
};
</script>
<style lang="less">
</style>