<template>
  <div class="team-area pb-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 mx-auto">
          <div class="site-heading text-center">
            <span
              class="site-title-tagline"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText1($event)"
            >
              {{ data.text1 }}</span
            >
            <h2
              class="site-title"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText2($event)"
            ></h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 col-lg-3" v-for="(d, k) in data.data" :key="k">
          <div class="team-item">
            <div class="team-img" v-on:click="editItemImg(k)">
              <ImgThumb :src="d.img" width="300" height="300" alt="thumb" />
            </div>
            <div class="team-content">
              <div class="team-bio">
                <h5
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemName($event)"
                >
                  {{ d.name }}
                </h5>
                <span
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemTitle($event)"
                  >{{ d.title }}</span
                >
              </div>

              <div v-if="edit == 'true'">
                <i v-on:click="removeObject(k)" class="fas fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import ImgThumb from "@/components/Shared/ImgThumb.vue";
import linhhm from "../../linhhm.bootstrap";
export default {
  data() {
    return {
      lhmIMG: null,
    };
  },
  props: ["edit", "data"],
  components: { ImgThumb },
  mounted() {
    if (this.edit) {
      this.lhmIMG = new linhhm.Image(false);
    }
    let text2 = this.$el.querySelector(".site-title");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
  },
  methods: {
    editItemImg(key) {
      let t = this;
      if (this.edit == "true") {
        t.lhmIMG.setCallback((src) => {
          t.data.data[key].img = src;
        });
        t.lhmIMG.openDialog();
      }
    },
    editItemName(event, key) {
      let t = this;
      t.data.data[key].name = event.target.innerText;
    },
    editItemTitle(event, key) {
      let t = this;
      t.data.data[key].title = event.target.innerText;
    },
    editText1(event) {
      let t = this;
      t.data.text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerText;
    },
    addObject() {
      let t = this;
      t.data.data.push({
        img: "/img/teams/Card-1.png",
        name: "Nguyễn Bảo Ngọc",
        title: "Giáo viên Toán",
      });
    },

    removeObject(key) {
      let t = this;
      Swal.fire({
        title: "Xác nhận xóa",
        showCancelButton: true,
        confirmButtonText: "Xóa",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (typeof t.data.data[key] != "undefined") {
            t.data.data.splice(key, 1);
          }
        }
      });
    },
  },
};
</script>