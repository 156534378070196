import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store/index";
let router;
function redirectToAdminLogin() {
  if (store.state.adminLogin == null) {
    if (/^\/admin\/.+/.test(window.location.pathname)) {
      store.commit("adminRedirectLink", window.location.pathname);
    }
    router.replace("/admin/login");
  }
}
function redirectToRobotLogin() {
  if (store.state.robotLogin == null) {
    if (/^\/robot\/.+/.test(window.location.pathname)) {
      store.commit("robotRedirectLink", window.location.pathname);
    }
    router.replace("/robot/login");
  }
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gioi-thieu',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/anh',
    name: 'HinhAnh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HinhAnh.vue')
  },
  {
    path: '/lien-he',
    name: 'ContactView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ContactView.vue')
  },
  {
    path: '/shop',
    name: 'ShopView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ShopView.vue')
  },
  {
    path: '/khoa-hoc',
    name: 'KhoaHocView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/KhoaHocView.vue')
  },
  {
    path: '/khoa-hoc/:alias/:id',
    name: 'KhoaHocDetailView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/KhoaHocDetailView.vue')
  },
  {
    path: '/blogs',
    name: 'BlogsView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/BlogView.vue')
  },
  {
    path: '/blogs/:alias/:id',
    name: 'BlogsViewWithId',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/BlogView.vue')
  },
  {
    path: '/blog/:alias/:id',
    name: 'ArticleView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ArticleView.vue')
  },
  {
    path: '/san-pham/:alias/:id',
    name: 'ProductView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ProductView.vue')
  },
  {
    path: '/shop/:make',
    name: 'ShopViewWithMake',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ShopView.vue')
  },
  {
    path: '/blog/:id/:alias',
    name: 'blogView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/BlogView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/HomeConfig/HomeView.vue')
    }
  },
  {
    path: '/admin/about',
    name: 'AdminAbout',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/HomeConfig/AboutView.vue')
    }
  },
  {
    path: '/admin/product-option',
    name: 'AdminProductOption',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductOptionView.vue')
    }
  },
  {
    path: '/admin/product-option/new',
    name: 'AdminProductNewOption',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductOptionNewView.vue')
    }
  },
  {
    path: '/admin/product-option/:id',
    name: 'AdminProductEditOption',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductOptionEditView.vue')
    }
  },
  {
    path: '/admin/product/:id',
    name: 'AdminProductWithId',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductView.vue')
    }
  },
  {
    path: '/admin/product/new/:id',
    name: 'AdminProductNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductNewView.vue')
    }
  },
  {
    path: '/admin/category/new/:id',
    name: 'AdminCategoryNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/CategoryNewView.vue')
    }
  },
  {
    path: '/admin/category/edit/:id',
    name: 'AdminCategoryEditView',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/CategoryEditView.vue')
    }
  },
  {
    path: '/admin/product/edit/:id',
    name: 'AdminProductEditView',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductEditView.vue')
    }
  },
  {
    path: '/admin/article/:id',
    name: 'AdminArticleWithId',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleView.vue')
    }
  },
  {
    path: '/admin/article/new/:id',
    name: 'AdminArticleNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleNewView.vue')
    }
  },
  {
    path: '/admin/article/edit/:id',
    name: 'AdminArticleEdit',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleEditView.vue')
    }
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => {
      return import('../views/Admin/LoginView.vue')
    }
  },
  {
    path: '/admin/article-group/new/:id',
    name: 'AdminArticleGroupNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleGroupNewView.vue')
    }
  },
  {
    path: '/admin/article-group/edit/:id',
    name: 'AdminArticleGroupEditView',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleGroupEditView.vue')
    }
  },
  {
    path: '/robot/login',
    name: 'RobotLogin',
    component: () => import('../views/Robot/RobotLogin.vue')
  },
  {
    path: '/robot',
    name: 'Robot',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotClass.vue');
    }
  },
  {
    path: '/robot/devices-manager',
    name: 'Robot Device Manager',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotDevice.vue');
    }
  },
  {
    path: '/robot/user-manager',
    name: 'Robot User Manager',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotUser.vue');
    }
  },
  {
    path: '/robot/my-info',
    name: 'Robot My Info',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotMyInfo.vue');
    }
  },
  {
    path: '/robot/class-manager',
    name: 'Robot Class',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotClassManager.vue');
    }
  },
  {
    path: '/robot/class',
    name: 'Robot Class Manager',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotClass.vue');
    }
  },
  {
    path: '/robot/class/:classId',
    name: 'Robot Class Detail',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Robot/RobotClassDetail.vue');
    }
  },

  {
    path: '/robot/:classId/bh1',
    name: 'robot_bh1',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Learn/BaiHoc1.vue');
    }
  },


  {
    path: '/robot/:classId/game-bh1',
    name: 'robot_bh1_game',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Learn/GameBaiHoc1.vue');
    }
  },
  {
    path: '/robot/:classId/giao-vien/game-bh1',
    name: 'giao_vien_robot_bh1_game',
    component: () => {
      redirectToRobotLogin();
      return import('../views/ChoGiaoVien/GameBaiHoc1.vue');
    }
  },
  {
    path: '/robot/:classId/game-bh2',
    name: 'robot_bh2_game',
    component: () => {
      redirectToRobotLogin();
      return import('../views/Learn/GameBaiHoc2.vue');
    }
  },
  {
    path: '/robot/:classId/giao-vien/game-bh2',
    name: 'giao_vien_robot_bh2_game',
    component: () => {
      redirectToRobotLogin();
      return import('../views/ChoGiaoVien/GameBaiHoc2.vue');
    }
  },
]

router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
