<template>
  <div class="header-top">
    <div class="container">
      <div class="header-top-wrapper">
        <div class="header-top-left">
          <div class="header-top-contact">
            <ul>
              <li>
                <div class="header-top-contact-icon">
                  <img src="/assets/img/icon/live-chat.svg" alt="" />
                </div>
                <div class="header-top-contact-info">
                  <a href="#">Live Chat</a>
                </div>
              </li>
              <li>
                <div class="header-top-contact-icon">
                  <img src="/assets/img/icon/mail.svg" alt="" />
                </div>
                <div class="header-top-contact-info">
                  <a href="../cdn-cgi/l/email-protection.html#5c35323a331c39243d312c3039723f3331"><span
                      class="__cf_email__"
                      data-cfemail="7910171f16391c01181409151c571a1614">support@azzura.com</span></a>
                </div>
              </li>
              <li>
                <div class="header-top-contact-icon">
                  <img src="/assets/img/icon/clock.svg" alt="" />
                </div>
                <div class="header-top-contact-info">
                  <a href="#">Thứ 2 - Thứ 7 (8:00 AM - 17:00 PM)</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="header-top-right">
          <div class="header-top-social">
            <a href="https://facebook.com/azzuravietnam" target="__blank"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTop",
  props: {},
};
</script>