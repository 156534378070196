const url = require('url');
import _ from 'underscore';
import jQuery from 'jquery';
import Backbone from 'backbone';
const $ = jQuery;
var linhhm = {
    Core: {
        randomString: function (length) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        },
        randomNumber: function (length) {
            var text = "";
            var possible = "0123456789";
            for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        },
        upload: function (fd, option) {
            if (fd instanceof HTMLFormElement) {
                fd = new FormData(fd);
            }
            var defaultOption = {
                processData: (fd instanceof FormData) ? false : true,
                contentType: (fd instanceof FormData) ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
                forceSync: false,
                dataType: "JSON",
                type: "POST",
                cache: false,
                url: document.URL,
                error: function () {
                },
                process: function (p) {
                    if (p == 100) {
                        $("#process-bar").show().width("100%");
                        window.setTimeout(function () {
                            $("#process-bar").width(0).hide();
                        }, 500);
                    } else {
                        $("#process-bar").show().width(p + "%");
                    }
                },
                complete: function () { }
            }
            if (!option.url) {
                throw new Error('Ajax thieu url');
            }
            var setting = _.extend(defaultOption, option);
            var xhr = $.ajaxSettings.xhr();
            if (setting.process && xhr.upload) {
                xhr.upload.addEventListener('progress', function (event) {
                    var percent = 0;
                    var position = event.loaded || event.position;
                    var total = event.total || event.totalSize;
                    if (event.lengthComputable) {
                        percent = Math.ceil(position / total * 100);
                    }
                    if (setting.process) {
                        setting.process.call(this, percent);
                    }
                }, false);
            }
            var uploadObject = new linhhm.Core.Upload(xhr, setting, fd);
            if (!setting.wait) {
                uploadObject.run();
            }
            return uploadObject;
        },
        dauTiengViet: function (input) {
            var coDau = Array("à", "á", "ạ", "ả", "ã", "â", "ầ", "ấ", "ậ", "ẩ", "ẫ", "ă", "ằ", "ắ", "ặ", "ẳ", "ẵ", "è", "é", "ẹ", "ẻ", "ẽ", "ê", "ề", "ế", "ệ", "ể", "ễ", "ì", "í", "ị", "ỉ", "ĩ", "ò", "ó", "ọ", "ỏ", "õ", "ô", "ồ", "ố", "ộ", "ổ", "ỗ", "ơ", "ờ", "ớ", "ợ", "ở", "ỡ", "ù", "ú", "ụ", "ủ", "ũ", "ư", "ừ", "ứ", "ự", "ử", "ữ", "ỳ", "ý", "ỵ", "ỷ", "ỹ", "đ", "À", "Á", "Ạ", "Ả", "Ã", "Â", "Ầ", "Ấ", "Ậ", "Ẩ", "Ẫ", "Ă", "Ằ", "Ắ", "Ặ", "Ẳ", "Ẵ", "È", "É", "Ẹ", "Ẻ", "Ẽ", "Ê", "Ề", "Ế", "Ệ", "Ể", "Ễ", "Ì", "Í", "Ị", "Ỉ", "Ĩ", "Ò", "Ó", "Ọ", "Ỏ", "Õ", "Ô", "Ồ", "Ố", "Ộ", "Ổ", "Ỗ", "Ơ", "Ờ", "Ớ", "Ợ", "Ở", "Ỡ", "Ù", "Ú", "Ụ", "Ủ", "Ũ", "Ư", "Ừ", "Ứ", "Ự", "Ử", "Ữ", "Ỳ", "Ý", "Ỵ", "Ỷ", "Ỹ", "Đ");
            var khongDau = Array("a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "e", "e", "e", "e", "e", "e", "e", "e", "e", "e", "e", "i", "i", "i", "i", "i", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "u", "u", "u", "u", "u", "u", "u", "u", "u", "u", "u", "y", "y", "y", "y", "y", "d", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "A", "E", "E", "E", "E", "E", "E", "E", "E", "E", "E", "E", "I", "I", "I", "I", "I", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "U", "U", "U", "U", "U", "U", "U", "U", "U", "U", "U", "Y", "Y", "Y", "Y", "Y", "D");
            var output = input;
            for (var i = 0; i < coDau.length; i++) {
                output = output.replace(new RegExp(coDau[i], 'g'), khongDau[i]);
            }
            return output;
        },
        stringToAlias: function (input) {
            return window.linhhm.Utilities.dauTiengViet(input).toLowerCase().replace(/[^a-z0-9-]/i, '').replace(/-+/, '-');
        },
        convertRedirectForm: function (str) {
            return str.replace(/-([a-z])/, function (st0, st1) {
                return st1.toUpperCase();
            });
        },
        parseURL: function (url) {
            var parser = document.createElement('a'), searchObject = {}, queries, split, i;
            parser.href = url;
            queries = parser.search.replace(/^\?/, '').split('&');
            for (i = 0; i < queries.length; i++) {
                split = queries[i].split('=');
                searchObject[split[0]] = split[1];
            }
            var path = parser.pathname;
            var routerArr = [];
            var moduleList = ["index", "admin"];
            var router = { module: 'index', controller: 'index', action: 'index', param: [[], {}, []] }
            if (!(/^\/+$/.test(path))) {
                path = path.replace(/^\/+/, "").replace(/\/+$/, "").replace(/\s+/, "").replace(/\/+/, "/");
                routerArr = path.split(/\//);
                i = 0;
                if (typeof routerArr[i] != "undefined") {
                    if (moduleList.indexOf(routerArr[i]) != -1) {
                        router.module = linhhm.Core.convertRedirectForm(routerArr[i]);
                        delete routerArr[i];
                        i++;
                    }
                    if (router.module == "index") {
                        router.controller = "index";
                        if (typeof routerArr[i] != "undefined") {
                            router.action = linhhm.Core.convertRedirectForm(routerArr[i]);
                            delete routerArr[i];
                        }
                    } else {
                        if (typeof routerArr[i] != "undefined") {
                            router.controller = linhhm.Core.convertRedirectForm(routerArr[i]);
                            delete routerArr[i];
                            i++;
                            if (typeof routerArr[i] != "undefined") {
                                router.action = linhhm.Core.convertRedirectForm(routerArr[i]);
                                delete routerArr[i];
                            }
                        }
                    }
                }
                var tmp = [];
                for (i = 0; i < routerArr.length; i++) {
                    if (typeof routerArr[i] != "undefined") {
                        router.param[0].push(routerArr[i]);
                        tmp = routerArr[i].match(/^(.+)\.([^.]+)$/);
                        if (tmp) {
                            router.param[1][tmp[1]] = tmp[2];
                        } else {
                            router.param[2].push(routerArr[i]);
                        }
                    }
                }
            }
            return {
                protocol: parser.protocol,
                host: parser.host,
                hostname: parser.hostname,
                port: parser.port,
                pathname: parser.pathname,
                search: parser.search,
                searchObject: searchObject,
                hash: parser.hash,
                router: router
            }
        },
        inherit: function (p) {
            if (p == null) throw TypeError();
            if (Object.create) return Object.create(p);
            var t = typeof p;
            if (t !== "object" && t !== "function") throw TypeError();

            function f() {
            } f.prototype = p;
            return new f();
        },
        eventAbstract: function () {
            throw new Error("abstract class only");
        },
        stringToDom: function (s) {
            return $.parseHTML(s);
        },
        rgbaTohex: function (rgb) {
            var color = (rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)) ? rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i) : rgb.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
            return (color && color.length === 4) ? "#" + ("0" + parseInt(color[1], 10).toString(16)).slice(-2) + ("0" + parseInt(color[2], 10).toString(16)).slice(-2) + ("0" + parseInt(color[3], 10).toString(16)).slice(-2) : '';
        }
    },
    Php: {
        sprintf: function () {
            var regex = /%%|%(\d+\$)?([-+'#0 ]*)(\*\d+\$|\*|\d+)?(?:\.(\*\d+\$|\*|\d+))?([scboxXuideEfFgG])/g;
            var a = arguments;
            var i = 0;
            var format = a[i++];
            var pad = function (str, len, chr, leftJustify) {
                if (!chr) {
                    chr = ' ';
                }
                var padding = (str.length >= len) ? '' : new Array(1 + len - str.length >>> 0).join(chr);
                return leftJustify ? str + padding : padding + str;
            };
            var justify = function (value, prefix, leftJustify, minWidth, zeroPad, customPadChar) {
                var diff = minWidth - value.length;
                if (diff > 0) {
                    if (leftJustify || !zeroPad) {
                        value = pad(value, minWidth, customPadChar, leftJustify);
                    } else {
                        value = value.slice(0, prefix.length) + pad('', diff, '0', true) + value.slice(prefix.length);
                    }
                }
                return value;
            };
            var formatBaseX = function (value, base, prefix, leftJustify, minWidth, precision, zeroPad) {
                var number = value >>> 0;
                prefix = (prefix && number && { '2': '0b', '8': '0', '16': '0x' }[base]) || '';
                value = prefix + pad(number.toString(base), precision || 0, '0', false);
                return justify(value, prefix, leftJustify, minWidth, zeroPad);
            };
            var formatString = function (value, leftJustify, minWidth, precision, zeroPad, customPadChar) {
                if (precision !== null && precision !== undefined) {
                    value = value.slice(0, precision);
                }
                return justify(value, '', leftJustify, minWidth, zeroPad, customPadChar);
            };
            var doFormat = function (substring, valueIndex, flags, minWidth, precision, type) {
                var number, prefix, method, textTransform, value;
                if (substring === '%%') {
                    return '%';
                }
                var leftJustify = false;
                var positivePrefix = '';
                var zeroPad = false;
                var prefixBaseX = false;
                var customPadChar = ' ';
                var flagsl = flags.length;
                var j;
                for (j = 0; flags && j < flagsl; j++) {
                    switch (flags.charAt(j)) {
                        case ' ':
                            positivePrefix = ' ';
                            break;
                        case '+':
                            positivePrefix = '+';
                            break;
                        case '-':
                            leftJustify = true;
                            break;
                        case "'":
                            customPadChar = flags.charAt(j + 1);
                            break;
                        case '0':
                            zeroPad = true;
                            customPadChar = '0';
                            break;
                        case '#':
                            prefixBaseX = true;
                            break;
                    }
                }
                if (!minWidth) {
                    minWidth = 0;
                } else if (minWidth === '*') {
                    minWidth = +a[i++];
                } else if (minWidth.charAt(0) === '*') {
                    minWidth = +a[minWidth.slice(1, -1)];
                } else {
                    minWidth = +minWidth;
                }
                if (minWidth < 0) {
                    minWidth = -minWidth;
                    leftJustify = true;
                }
                if (!isFinite(minWidth)) {
                    throw new Error('sprintf: (minimum-)width must be finite');
                }
                if (!precision) {
                    precision = 'fFeE'.indexOf(type) > -1 ? 6 : (type === 'd') ? 0 : undefined;
                } else if (precision === '*') {
                    precision = +a[i++];
                } else if (precision.charAt(0) === '*') {
                    precision = +a[precision.slice(1, -1)];
                } else {
                    precision = +precision;
                }
                value = valueIndex ? a[valueIndex.slice(0, -1)] : a[i++];
                switch (type) {
                    case 's':
                        return formatString(String(value), leftJustify, minWidth, precision, zeroPad, customPadChar);
                    case 'c':
                        return formatString(String.fromCharCode(+value), leftJustify, minWidth, precision, zeroPad);
                    case 'b':
                        return formatBaseX(value, 2, prefixBaseX, leftJustify, minWidth, precision, zeroPad);
                    case 'o':
                        return formatBaseX(value, 8, prefixBaseX, leftJustify, minWidth, precision, zeroPad);
                    case 'x':
                        return formatBaseX(value, 16, prefixBaseX, leftJustify, minWidth, precision, zeroPad);
                    case 'X':
                        return formatBaseX(value, 16, prefixBaseX, leftJustify, minWidth, precision, zeroPad).toUpperCase();
                    case 'u':
                        return formatBaseX(value, 10, prefixBaseX, leftJustify, minWidth, precision, zeroPad);
                    case 'i':
                    case 'd':
                        number = +value || 0;
                        number = Math.round(number - number % 1);
                        prefix = number < 0 ? '-' : positivePrefix;
                        value = prefix + pad(String(Math.abs(number)), precision, '0', false);
                        return justify(value, prefix, leftJustify, minWidth, zeroPad);
                    case 'e':
                    case 'E':
                    case 'f':
                    case 'F':
                    case 'g':
                    case 'G':
                        number = +value;
                        prefix = number < 0 ? '-' : positivePrefix;
                        method = ['toExponential', 'toFixed', 'toPrecision']['efg'.indexOf(type.toLowerCase())];
                        textTransform = ['toString', 'toUpperCase']['eEfFgG'.indexOf(type) % 2];
                        value = prefix + Math.abs(number)[method](precision);
                        return justify(value, prefix, leftJustify, minWidth, zeroPad)[textTransform]();
                    default:
                        return substring;
                }
            };
            return format.replace(regex, doFormat);
        },
        in_array: function (needle, haystack, argStrict) {
            var key = '', strict = !!argStrict;
            if (strict) {
                for (key in haystack) {
                    if (haystack[key] === needle) {
                        return true;
                    }
                }
            } else {
                for (key in haystack) {
                    if (haystack[key] == needle) {
                        return true;
                    }
                }
            }
            return false;
        },
        number_format: function (number, decimals, dec_point, thousands_sep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number, prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point, s = '', toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + (Math.round(n * k) / k).toFixed(prec);
                };
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
        basename: function (path, suffix) {
            var b = path;
            var lastChar = b.charAt(b.length - 1);
            if (lastChar === '/' || lastChar === '\\') {
                b = b.slice(0, -1);
            }
            b = b.replace(/^.*[/\\]/g, '');
            if (typeof suffix === 'string' && b.substr(b.length - suffix.length) === suffix) {
                b = b.substr(0, b.length - suffix.length);
            }
            return b;
        },
        pathinfo: function (path, options) {
            var opt = ''
            var realOpt = ''
            var optName = ''
            var optTemp = 0
            var tmpArr = {}
            var cnt = 0
            var i = 0
            var haveBasename = false
            var haveExtension = false
            var haveFilename = false
            if (!path) {
                return false
            }
            if (!options) {
                options = 'PATHINFO_ALL'
            }
            var OPTS = {
                'PATHINFO_DIRNAME': 1,
                'PATHINFO_BASENAME': 2,
                'PATHINFO_EXTENSION': 4,
                'PATHINFO_FILENAME': 8,
                'PATHINFO_ALL': 0
            }
            for (optName in OPTS) {
                if (typeof OPTS[optName] != 'undefined') {
                    OPTS.PATHINFO_ALL = OPTS.PATHINFO_ALL | OPTS[optName]
                }
            }
            if (typeof options !== 'number') {
                options = [].concat(options)
                for (i = 0; i < options.length; i++) {
                    if (OPTS[options[i]]) {
                        optTemp = optTemp | OPTS[options[i]]
                    }
                }
                options = optTemp
            }
            var _getExt = function (path) {
                var str = path + ''
                var dotP = str.lastIndexOf('.') + 1
                return !dotP ? false : dotP !== str.length ? str.substr(dotP) : ''
            }
            if (options & OPTS.PATHINFO_DIRNAME) {
                var dirName = path.replace(/\\/g, '/').replace(/\/[^/]*\/?$/, '')
                tmpArr.dirname = dirName === path ? '.' : dirName
            }
            if (options & OPTS.PATHINFO_BASENAME) {
                if (haveBasename === false) {
                    haveBasename = linhhm.Php.basename(path)
                }
                tmpArr.basename = haveBasename
            }
            if (options & OPTS.PATHINFO_EXTENSION) {
                if (haveBasename === false) {
                    haveBasename = linhhm.Php.basename(path)
                }
                if (haveExtension === false) {
                    haveExtension = _getExt(haveBasename)
                }
                if (haveExtension !== false) {
                    tmpArr.extension = haveExtension
                }
            }
            if (options & OPTS.PATHINFO_FILENAME) {
                if (haveBasename === false) {
                    haveBasename = linhhm.Php.linhhm.Php.basename(path)
                }
                if (haveExtension === false) {
                    haveExtension = _getExt(haveBasename)
                }
                if (haveFilename === false) {
                    haveFilename = haveBasename.slice(0, haveBasename.length - (haveExtension ? haveExtension.length + 1 : haveExtension === false ? 0 : 1))
                }
                tmpArr.filename = haveFilename
            }
            cnt = 0
            for (opt in tmpArr) {
                if (typeof tmpArr[opt] != 'undefined') {
                    cnt++
                    realOpt = opt
                }
            }
            if (cnt === 1) {
                return tmpArr[realOpt]
            }
            return tmpArr
        }
    },
    notification: window.Notification || window.mozNotification || window.webkitNotification,
    host: process.env.NODE_ENV === "production" ? "" : "",
    url(link) {
        var linkData = url.parse(link);
        if (linkData.protocol) {
            return link;
        }
        return linhhm.host + link;
    },
    randomString(length, spec) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
        if (spec) {
            possible += "@#$%^&*()-+=_";
        }
        for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }
};
linhhm.Core.Upload = function (xhr, setting, fd) {
    this.xhr = xhr;
    this.setting = setting;
    this.fd = fd;
};
linhhm.Core.Upload.prototype = linhhm.Core.inherit(Backbone.Events);
_.extend(linhhm.Core.Upload.prototype, {
    getXhr: function () {
        return this.xhr;
    },
    run: function () {
        var t = this;
        var setting = t.setting;
        var fd = t.fd;
        var xhr = t.xhr;
        $.ajax({
            url: setting.url,
            type: setting.type,
            dataType: setting.dataType,
            data: fd,
            cache: setting.cache,
            contentType: setting.contentType,
            processData: setting.processData,
            forceSync: setting.forceSync,
            xhr: function () {
                return xhr;
            },
            success: function (data, status, xhr) {

                if (data.errorLogin) {
                    window.alert("Hết hạn đăng nhập, bạn hãy đăng nhập lại, có thể chọn nhớ đăng nhập để lưu trạng thái")
                    window.location = "/admin/account/sign-in";
                } else if (setting.success) {
                    if (setting.linhhmStandard) {
                        if (data.status) {
                            if (!((typeof data.data) == 'undefined')) {
                                arguments[0] = data.data;
                            } else {
                                arguments[0] = [];
                            }
                            setting.success.apply(this, arguments);
                        } else {
                            setting.error.apply(this, arguments);
                        }
                    } else {
                        setting.success.apply(this, arguments);
                    }
                }
                t.trigger('success', data, status, xhr);
            },
            error: function (xhr, status, error) {
                if (setting.error) {
                    setting.error.apply(this, arguments);
                }
                t.trigger('error', xhr, status, error);
            },
            complete: function (xhr, status) {
                if (setting.complete) {
                    setting.complete.apply(this, arguments);
                }
                t.trigger('complete', xhr, status);
            }
        });
    }
});
_.extend(linhhm.Core.eventAbstract.prototype, {
    eventInit: function () {
        var t = this;
        t._eventData = [];
    },
    on: function () {
        var arg = arguments;
        var t = this;
        if (arg.length < 2) {
            throw new Error("su kien can co ten");
        } else {
            var name = arguments[0];
            var hander = arguments[1];
            if (!_.isString(name)) {
                throw new Error("ten su kien phai la 1 chuoi");
            } else if (!name.match(/^[a-z\s0-9]+$/i)) {
                throw new Error("ten su kien tu a-z 0-9");
            }
            if (!_.isFunction(hander)) {
                throw new Error("trinh xu ly phai la mot funciton");
            }
            if (name.match(/\s+/)) {
                name = name.split(/\s+/);
            }
            if (_.isArray(name)) {
                for (var i = 0; i < name.length; i++) {
                    t._eventData.push({ name: name[i], hander: hander });
                }
            } else {
                t._eventData.push({ name: name, hander: hander });
            }
        }
    },
    once: function () {
        var arg = arguments;
        var t = this;
        if (arg.length < 2) {
            throw new Error("su kien can co ten");
        } else {
            var name = arguments[0];
            var hander = arguments[1];
            if (!_.isString(name)) {
                throw new Error("ten su kien phai la 1 chuoi");
            } else if (!name.match(/^[a-z\s0-9]+$/i)) {
                throw new Error("ten su kien tu a-z 0-9");
            }
            if (!_.isFunction(hander)) {
                throw new Error("trinh xu ly phai la mot funciton");
            }
            if (name.match(/\s+/)) {
                name = name.split(/\s+/);
            }
            if (_.isArray(name)) {
                for (var i = 0; i < name.length; i++) {
                    t._eventData.push({ name: name[i], hander: hander, once: true });
                }
            } else {
                t._eventData.push({ name: name, hander: hander, once: true });
            }
        }
    },
    off: function () {
        var arg = arguments;
        var t = this;
        var name;
        var hander = undefined;
        if (arg.length < 1) {
            throw new Error("Cần có ít nhất một tham số");
        } else if (arg.length == 1) {
            name = arg[0];
        } else {
            name = arg[0];
            hander = arg[1];
        }
        if (!_.isString(name)) {
            throw new Error("Tên sự kiện là một chuỗi");
        } else if (!name.match(/^[a-z0-9]+$/i)) {
            throw new Error("ten su kien tu a-z 0-9");
        }
        if (!(_.isFunction(hander) || _.isUndefined(hander))) {
            throw new Error("trình xử lý sự kiện phải là một function");
        }
        if (_.isFunction(hander)) {
            for (let i = 0; i < t._eventData.length; i++) {
                if (t._eventData[i]["name"] == name && hander == t._eventData[i]["hander"]) {
                    delete t._eventData[i];
                }
            }
        } else {
            for (let i = 0; i < t._eventData.length; i++) {
                if (t._eventData[i]["name"] == name) {
                    delete t._eventData[i];
                }
            }
        }
        t._eventData = _.compact(t._eventData);
    },
    hasHander: function () {
        var arg = arguments;
        var t = this;
        var name;
        var hander = undefined;
        var flag = false;
        if (arg.length < 1) {
            throw new Error("Cần có ít nhất một tham số");
        } else if (arg.length == 1) {
            name = arg[0];
        } else {
            name = arg[0];
            hander = arg[1];
        }
        if (!_.isString(name)) {
            throw new Error("Tên sự kiện là một chuỗi");
        } else if (!name.match(/^[a-z0-9]+$/i)) {
            throw new Error("ten su kien tu a-z 0-9");
        }
        if (!(_.isFunction(hander) || _.isUndefined(hander))) {
            throw new Error("trình xử lý sự kiện phải là một function");
        }
        if (_.isFunction(hander)) {
            for (let i = 0; i < t._eventData.length; i++) {
                if (t._eventData[i]["name"] == name && hander == t._eventData[i]["hander"]) {
                    flag = true;
                    break;
                }
            }
        } else {
            for (let i = 0; i < t._eventData.length; i++) {
                if (t._eventData[i]["name"] == name) {
                    flag = true;
                    break;
                }
            }
        }
        return flag;
    },
    fire: function () {
        var arg = arguments;
        var t = this;
        var name;
        var param = [];
        if (arg.length < 1) {
            throw new Error("Cần có ít nhất một tham số");
        } else {
            name = arg[0];
        }
        if (!_.isString(name)) {
            throw new Error("Tên sự kiện là một chuỗi");
        } else if (!name.match(/^[a-z0-9A-Z]+$/i)) {
            throw new Error("ten su kien tu a-z 0-9");
        }
        for (let i = 1; i < arg.length; i++) {
            param.push(arg[i]);
        }
        var once = false;
        for (let i = 0; i < t._eventData.length; i++) {
            if (t._eventData[i]["name"] == name) {
                t._eventData[i]["hander"].apply(t, param);
                if (t._eventData[i]["once"]) {
                    delete t._eventData[i];
                    once = true;
                }
            }
        }
        if (once) {
            t._eventData = _.compact(t._eventData);
        }
    }
});
linhhm.Data = function () {
    this._data = [];
}
linhhm.Data.data = {};
linhhm.Data.prototype = linhhm.Core.inherit(Backbone.Events);
_.extend(linhhm.Data.prototype, {
    e: function () {
        var t = this;
        var setFlag = false;
        var key;
        var value;
        if (arguments.length < 1) {
            throw new Error("thieu tham so");
        } else if (arguments.length == 1) {
            key = arguments[0];
        } else {
            key = arguments[0];
            value = arguments[1];
            setFlag = true;
        }
        var flagExists = false;
        if (setFlag) {
            for (let i = 0; i < t._data.length; i++) {
                if (t._data[i] && t._data[i]["key"] == key) {
                    flagExists = true;
                    t._data[i]["value"] = value;
                    break;
                }
            }
            if (flagExists) {
                t.trigger("updateElement", key, value);
            } else {
                t._data.push({ key: key, value: value });
                t.trigger("newElement", key, value);
            }
            t.trigger("setElement", key, value);
            return t;
        } else {
            for (let i = 0; i < t._data.length; i++) {
                if (t._data[i] && t._data[i]["key"] == key) {
                    flagExists = true;
                    t.trigger("getElement", key, t._data[i]["value"]);
                    return t._data[i]["value"];
                }
            }
            return;
        }
    },
    remove: function (key) {
        var t = this;
        var flagRemove = false;
        var value;
        for (var i = 0; i < t._data.length; i++) {
            if (t._data[i] && t._data[i]["key"] == key) {
                value = t._data[i]["value"];
                delete t._data[i];
                flagRemove = true;
                break;
            }
        }
        if (flagRemove) {
            t.trigger("removeElement", key, value);
            t._data = _.compact(t._data);
        }
        return this;
    },
    exists: function (key) {
        var t = this;
        var flag = false;
        for (var i = 0; i < t._data.length; i++) {
            if (t._data[i].key == key) {
                flag = true;
                break;
            }
        }
        return flag;
    },
    each: function (f) {
        var t = this;
        if (_.isFunction(f)) {
            for (var i = 0; i < t._data.length; i++) {
                f.call(t, t._data[i]["key"], t._data[i]["value"]);
            }
        } else {
            throw new Error("fai la function");
        }
    },
    search: function (key_word) {
        var t = this;
        var result = [];
        for (var i = 0; i < t._data.length; i++) {
            if (_.isString(t._data[i].key) && t._data[i].key.indexOf(key_word) != -1) {
                result.push(t._data[i]);
            }
        }
        return result;
    },
    searchValue: function (key_word) {
        var t = this;
        var result = [];
        for (var i = 0; i < t._data.length; i++) {
            if (_.isString(t._data[i].value) && t._data[i].value.indexOf(key_word) != -1) {
                result.push(t._data[i]);
            }
        }
        return result;
    },
    searchCallback: function (key_word, callback) {
        var t = this;
        var result = [];
        for (var i = 0; i < t._data.length; i++) {
            if (callback(key_word, t._data[i])) {
                result.push(t._data[i]);
            }
        }
        return result;
    }
});
linhhm.data = function (namespace) {
    if (!_.isString(namespace)) {
        throw new Error("namespace phai la 1 chuoi");
    } else if (!namespace.match(/^[a-z0-9]+$/i)) {
        throw new Error("namespace chỉ cứa a-z 0-9");
    }
    if (!(linhhm.Data.data[namespace] instanceof linhhm.Data)) {
        linhhm.Data.data[namespace] = new linhhm.Data();
    }
    return linhhm.Data.data[namespace];
}
linhhm.UI = {
    notify: function (titleText, bodyText, icon, callback) {
        if (linhhm.notification) linhhm.notification.requestPermission(function () {
        });
        if ('undefined' === typeof linhhm.notification) return false;
        var noty = new linhhm.notification(titleText, {
            body: bodyText,
            dir: 'ltr',
            lang: 'vi',
            tag: 'notificationPopup',
            icon: icon || '/public/images/logo/logo.png'
        });
        if (callback) {
            if (callback.click) noty.onclick = callback.click;
            if (callback.error) noty.onerror = callback.error;
            if (callback.show) noty.onshow = callback.show;
            if (callback.close) noty.onclose = callback.close;
        }
        window.setTimeout(function () {
            noty.close();
        }, 5000);
        return true;
    },
    confirm: function (options) {
        if (typeof options == 'string') {
            options = { messenger: options };
        }
        if (_.isArray(options.messenger)) {
            var messenger = '<ul>';
            for (var i = 0; i < options.messenger.length; i++) {
                messenger += '<li>' + options.messenger[i] + '</li>';
            }
            messenger += '</ul>';
            options.messenger = messenger;
        }
        var confirmBox = $('<div class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">\n' +
            '  <div class="modal-dialog modal-dialog-centered" role="document">\n' +
            '    <div class="modal-content">\n' +
            '      <div class="modal-header">\n' +
            '        <h5 class="modal-title"></h5>\n' +
            '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
            '          <span aria-hidden="true">&times;</span>\n' +
            '        </button>\n' +
            '      </div>\n' +
            '      <div class="modal-body"></div>\n' +
            '      <div class="modal-footer">\n' +
            '        <button type="button" class="btn btn-secondary ok" data-dismiss="modal">OK</button>\n' +
            '        <button type="button" class="btn btn-primary cancel">Close</button>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>').appendTo('body');


        confirmBox.find("button.cancel").on('click', function () {
            confirmBox.modal("hide")
            confirmBox.remove();
            $('body').removeClass('modal-open');

            $('body').css("padding-right", "");
            if (options && options.cancelCallback) {
                options.cancelCallback();
            }
        });
        confirmBox.find("button.ok").on('click', function () {
            $('body').removeClass('modal-open');

            $('body').css("padding-right", "");
            confirmBox.modal("hide")
            confirmBox.remove();
            if (options && options.okCallback) {
                options.okCallback();
            }
        });
        if (options.title) {
            confirmBox.find(".modal-title").html(options.title);
        }
        if (options.messenger) {
            confirmBox.find(".modal-body").html(options.messenger);
        }
        confirmBox.modal({ backdrop: false });
    },
    notice: function (options) {
        if (typeof options == 'string') {
            options = { messenger: options };
        }
        if (_.isArray(options.messenger)) {
            var messenger = '<ul>';
            for (var i = 0; i < options.messenger.length; i++) {
                messenger += '<li>' + options.messenger[i] + '</li>';
            }
            messenger += '</ul>';
            options.messenger = messenger;
        }
        var noticeBox = $('<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">\n' +
            '  <div class="modal-dialog" role="document">\n' +
            '    <div class="modal-content">\n' +
            '      <div class="modal-header">\n' +
            '        <h5 class="modal-title">Modal title</h5>\n' +
            '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
            '          <span aria-hidden="true">&times;</span>\n' +
            '        </button>\n' +
            '      </div>\n' +
            '      <div class="modal-body">\n' +
            '      </div>\n' +
            '      <div class="modal-footer">\n' +
            '        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>').appendTo('body');
        noticeBox.find(".modal-footer > button").off("click").on('click', function () {
            noticeBox.modal('hide');
            noticeBox.remove();
            $('body').removeClass('modal-open');
            $('body').css("padding-right", "");
            if (options && options.callback) {
                options.callback();
            }
        });
        if (options.title) {
            noticeBox.find(".modal-title").html(options.title);
        }
        if (options.messenger) {
            noticeBox.find(".modal-body").html(options.messenger);
        }
        noticeBox.modal({ backdrop: false });
    }, template: function (selector) {
        return _.template($(selector).html());
    }, phanTrang: function (link, cPage, tPage) {
        var html = '';
        if (tPage > 1) {
            var start = (cPage >= 5) ? (cPage - 4) : 1;
            var end = (tPage - cPage > 5) ? (cPage + 4) : tPage;
            html += '<ul class="pagination">';
            for (var i = start; i < cPage; i++) {
                html += '<li class="waves-effect"><a data-page="' + i + '" href="' + linhhm.Php.sprintf(link, i) + '">' + (i) + '</a></li>';
            }
            html += '<li class="active"><a href="javascript:void(0);">' + cPage + '</a></li>';
            for (i = cPage + 1; i <= end; i++) {
                html += '<li class="waves-effect"><a data-page="' + i + '" href="' + linhhm.Php.sprintf(link, i) + '">' + (i) + '</a></li>';
            }
            html += '</ul>';
        }
        return html;
    }
}
linhhm.Utilities = {
    getFormData: function (form) {
        return $(form).serialize();
    },
    getFormArray: function (form) {
        var tmp = $(form).serializeArray();
        var data = {};
        for (var i = 0; i < tmp.length; i++) {
            data[tmp[i]['name']] = tmp[i]['value'];
        }
        return data;
    },
    optUrl: function (src, size) {
        var l = linhhm.Utilities.getLocation(src);
        var pathInfo = linhhm.Php.pathinfo(l.pathname);
        if (l.hostname == window.location.hostname && typeof pathInfo.extension != "undefined" && (pathInfo.extension.toLowerCase() == "jpg" || pathInfo.extension.toLowerCase() == "png"
            || pathInfo.extension.toLowerCase() == "gif" || pathInfo.extension.toLowerCase() == "webp")) {
            var arr = pathInfo.dirname.split("/");
            if (typeof arr[1] != "undefined" && arr[1] == "public" && typeof arr[2] != "undefined" && arr[2] == "images") {
                arr[2] = "images-thumbnail";
            }
            var newDir = arr.join("/");
            src = newDir + "/" + pathInfo.filename + "lhmsize-" + size + "." + pathInfo.extension;
        }
        return src;
    },
    getLocation: function (href) {
        var l = document.createElement("a");
        l.href = href;
        return l;
    },

    resizeImage: function (src, width, height, convertTo) {
        var arr = src.split("/");
        if (typeof arr[1] == 'string' && arr[1] == "img") {
            arr[1] = "img-thumb";
        }
        var newDir = arr.join("/");
        src = newDir + '.' + width + '.' + height + '.' + convertTo;
        return src;
    }
}


linhhm.Image = function (flagTitle) {
    var t = this;
    t.id = linhhm.Core.randomString(5);
    t.flag_title = true;
    if (typeof flagTitle == "undefined") t.flag_title = true; else
        t.flag_title = !!flagTitle;
    this.$b = $('<div class="lhmImage-box">' +
        '<div class="col-12"><div class="row lhmImage-toolbar">' +
        '<ul class="col s12 col-12">' +
        '<li id="' + t.id + '-new-directory"><span class="fa fa-folder"></span> new</li>' +
        '<li id="' + t.id + '-refresh-2"><span class="fa fa-folder"></span> Refresh content</li>' +
        '<li><input multiple id="' + t.id + '-upload" type="file" style="display: none"><label for="' + t.id + '-upload" class="fa fa-image"> Upload</label></li>' +
        '</ul>' +
        '</div></div>' +
        '<div class="col-12"><div class="row">' +
        '<div class="col s5 col-5 lhmImage-folder"><ul></ul></div>' +
        '<div class="col s7 col-7 lhmImage-list"><ul></ul></div>' +
        '</div></div>' +
        '<div class="context-menu">' +
        '<div class="overlay"></div>' +
        '<ul class="menu context-menu-image">' +
        '<li class="getlink">Lấy link ảnh</li>' +
        '<li class="rename">Đổi tên</li>' +
        '<li class="delete">Xóa</li>' +
        '</ul>' +
        '</div>' +
        '<div class="context-menu-2">' +
        '<div class="overlay"></div>' +
        '<ul class="menu context-menu-image">' +
        '<li class="rename">Đổi tên</li>' +
        '<li class="delete">Xóa</li>' +
        '</ul>' +
        '</div>' +
        '</div>');

    this.b = this.$b[0];
    this.f = this.$b.find('.lhmImage-folder > ul');
    this.l = this.$b.find('.lhmImage-list > ul');
    this.ul = this.$b.find('.lhmImage-toolbar input');
    this.contextMenu = this.$b.find("div.context-menu");
    this.contextMenu2 = this.$b.find("div.context-menu-2");
    this.contextMenu.find("> .overlay").on("click", function () {
        t.closeContextMenu();
    });
    this.contextMenu2.find("> .overlay").on("click", function () {
        t.closeContextMenu2();
    });
    t.namespace = 'img';
    document.body.appendChild(this.b);
    t.activeDirectory = null;
    $("#" + t.id + '-new-directory').on("click", function () {
        if (t.activeDirectory) {
            t.activeDirectory.newDirectory();
        }
    })
    $("#" + t.id + '-refresh-2').on("click", function () {
        if (t.activeDirectory) {
            t.activeDirectory.refresh2();
        }
    })
    this.imageD = new linhhm.ImageDirectory(this, null, 'img', 'img', this.f);
    this.imageD.showImage();
    t.currentDirObj = this.imageD;
    this.ul.on('change', function () {
        var file = this.files;
        if (file.length) {
            var form = new FormData();
            var count = 0;
            form.append('path', t.namespace);
            form.append('image', file[0]);
            /* for (var i = 0; i < file.length; i++) {
                if (file[i].type == 'image/jpeg' || file[i].type == 'image/png' || file[i].type == 'image/gif' || file[i].type == 'image/webp') {
                    count++;
                    form.append('images[]', file[i]);
                }
            } */
            count++;
            if (count) {
                linhhm.Core.upload(form, {
                    url: linhhm.url('/aapi/content-edit/ajax-upload-image'),
                    success: function (data) {
                        if (data.status) {
                            t.currentDirObj.loadChild(t.currentDirObj.showImage);
                        }
                    },
                    error: function () {
                        t.currentDirObj.loadChild(t.currentDirObj.showImage);
                    }

                })
            }
        }
    });
    this.clickOut = function (e) {
        var target = e.target;
        var inNode = false;
        while (target && target != document.body) {
            if (target == t.b) {
                inNode = true;
                break;
            }
            target = target.parentNode;
        }
        if (!inNode) {
            t.closeDialog();
        }
    }
}
linhhm.Image.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.Image.prototype, {
    loadDirectory: function () {
        this.imageD.loadChild();
    }, setCallback: function (callback, context) {
        this.callback = callback;
        this.callbackContext = context;
    }, openDialog: function () {
        var t = this;
        window.setTimeout(function () {
            t.$b.addClass('active');
            $(document).on('click', t.clickOut);
        }, 200);
    },
    closeDialog: function () {
        this.$b.removeClass('active');
        $(document).off('click', this.clickOut);
    },
    deleteImage: function (link, domUI, dirObj, fileName) {
        var t = this;
        linhhm.Core.upload({ link: link }, {
            url: linhhm.url("/aapi/content-edit/ajax-delete-image"), success: function (data) {
                if (data.status) {
                    domUI.remove();
                    for (var i = dirObj.childImg.length - 1; i >= 0; i--) {
                        if (dirObj.childImg[i] == fileName) dirObj.childImg.splice(i, 1);
                    }
                    t.closeContextMenu();
                }
            }, error: function () {
            }
        })
    },
    renameImage: function (link, domUI, dirObj, fileName) {
        var t = this;
        var pt = /^(.+)\.(jpg|png|gif|webp)$/i
        var result = pt.exec(fileName);

        var newName = prompt("Tên tập tin", result[1]);
        if (newName && newName !== result[1]) {
            linhhm.Core.upload({ link: link, newName: newName }, {
                url: linhhm.url("/aapi/content-edit/ajax-rename-image"),
                success: function (data) {
                    if (data.status) {
                        domUI.find("img")
                            .attr("src", linhhm.Utilities.resizeImage(data.data.newSrc, 100, 100, "webp"));
                        domUI.find("img")
                            .attr("data-src", data.data.newSrc);
                        domUI.find("div.name").text(linhhm.Php.pathinfo(data.data.newSrc).basename);
                        for (var i = dirObj.childImg.length - 1; i >= 0; i--) {
                            if (dirObj.childImg[i] == fileName) dirObj.childImg[i] = data.data.newName;
                        }
                        t.closeContextMenu();
                    }
                },
                error: function () {
                }
            })
        }
    },
    renameDir: function (link, domUI, dirObj, fileName) {
        var t = this;

        var newName = prompt("Tên thư mục", fileName);
        if (dirObj.parent) {
            if (!/^[a-zA-Z0-9-]+$/.exec(newName)) {
                alert("Tên thư mục không dấu, không ' '!");
                return t;
            }
            if (newName && newName !== fileName) {
                linhhm.Core.upload({ link: link, newName: newName }, {
                    url: linhhm.url("/aapi/content-edit/ajax-rename-dir"),
                    success: function (data) {
                        if (data.status) {
                            domUI.find("> div.name").text(newName);
                            dirObj.name = newName;
                            dirObj.updateNameSpace(dirObj.parent.namespace);
                            dirObj.loadChild();
                            t.closeContextMenu2();
                        } else {
                            linhhm.UI.notice(data.error[0]);
                        }
                    },
                    error: function () {
                    }
                })
            }
        } else {
            alert("Không thể đổi tên thư mục gốc");
        }


    },
    deleteDir: function (link, domUI, dirObj) {
        var t = this;
        if (dirObj.parent) {
            if (window.confirm("Xác nhận xóa")) {
                linhhm.Core.upload({ link: link }, {
                    url: linhhm.url("/aapi/content-edit/ajax-delete-dir"),
                    success: function (data) {
                        if (data.status) {
                            dirObj.removeUI();
                            t.closeContextMenu2();
                        } else {
                            linhhm.UI.notice(data.error[0]);
                        }
                    },
                    error: function () {
                    }
                })
            }
        }

    },
    openContextMenu: function () {
        var t = this;
        t.contextMenu.addClass("active");
    }, openContextMenu2: function () {
        var t = this;
        t.contextMenu2.addClass("active");
    }, closeContextMenu: function () {
        var t = this;
        t.contextMenu.removeClass("active");
    }, closeContextMenu2: function () {
        var t = this;
        t.contextMenu2.removeClass("active");
    }
})
linhhm.ImageDirectory = function (imgObj, parent, namespace, name, wrap) {
    this.imgObj = imgObj;
    this.parent = parent;
    this.wrap = wrap;
    this.name = name;
    this.namespace = namespace;
    this.childDir = [];
    this.childImg = [];
    this.loaded = false;
    this.buildUI();
}
linhhm.ImageDirectory.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.ImageDirectory.prototype, {
    loadChild: function (callback) {
        var t = this;
        for (var i in this.childDir) {
            this.childDir[i].removeUI();
        }
        this.childDir.length = 0;
        this.childImg.length = 0;
        linhhm.Core.upload({ directory: t.namespace }, {
            url: linhhm.url("/aapi/content-edit/ajax-get-directory-list"),
            success: function (data) {
                if (data.status) {
                    t.loaded = true;
                    for (let i = 0; i < data.data.dir.length; i++) {
                        var newNamespace = t.namespace + '/' + data.data.dir[i];
                        var flagHas = false;
                        for (var y = 0; y < t.childDir.length; y++) {
                            if (t.childDir[y].namespace == newNamespace) {
                                flagHas == true;
                                break;
                            }
                        }
                        if (!flagHas) {
                            t.childDir.push(new linhhm.ImageDirectory(t.imgObj, t, newNamespace, data.data.dir[i], t.f));
                        }
                    }
                    for (let i = 0; i < data.data.img.length; i++) {
                        t.childImg.push(data.data.img[i]);
                    }
                    if (callback) callback.call(t);
                }
            }
        });
    },
    loadImageOnly: function (callback) {
        var t = this;
        this.childImg.length = 0;
        linhhm.Core.upload({ directory: t.namespace }, {
            url: linhhm.url("/aapi/content-edit/ajax-get-directory-list"),
            success: function (data) {
                if (data.status) {
                    t.loaded = true;
                    for (var i = 0; i < data.data.img.length; i++) {
                        t.childImg.push(data.data.img[i]);
                    }
                    if (callback) callback.call(t);
                }
            }
        });
    },
    newDirectory: function () {
        var t = this;
        var name = prompt("Tên thư mục", "untitled");
        if (/^[a-zA-Z\-0-9]+$/.exec(name) === null) {
            alert("Tên thư mục không dấu, không ' '!");
            return t;
        }
        if (name) {
            linhhm.Core.upload({ name: name, directory: t.namespace }, {
                url: linhhm.url("/aapi/content-edit/ajax-new-directory"),
                success: function (data) {
                    if (data.status) {
                        if (t.loaded == true) {
                            var newNamespace = t.namespace + '/' + data.data.dir;
                            var flagHas = false;
                            for (var y = 0; y < t.childDir.length; y++) {
                                if (t.childDir[y].namespace == newNamespace) {
                                    flagHas == true;
                                    break;
                                }
                            }
                            if (!flagHas) {
                                t.childDir.push(new linhhm.ImageDirectory(t.imgObj, t, newNamespace, data.data.dir, t.f));
                            }
                        }
                    }
                }
            })
        }
    },
    refresh2: function () {
        var t = this;
        t.childImg = [];
        t.loadImageOnly(function () {
            t.showImage();
        });
    },
    buildUI: function () {
        var t = this;
        t.dom = $('<li><div class="name"></div><div class="ex"><span class="fa fa-plus"></span><span class="fa fa-minus"></span></div><ul></ul></li>');
        t.dom.find('> .name').html(t.name);
        t.dom.find('> .ex').on('click', function () {
            t.ex();
        });
        t.dom.find('> .name').on('click', function () {
        });
        t.dom.find('> .name').on('contextmenu', function (e) {
            e.originalEvent.preventDefault();

            var linkDir = '/' + t.namespace;
            var fileName = t.name;
            var deleteButton = t.imgObj.contextMenu2.find("> ul.menu.context-menu-image > li.delete");
            var renameButton = t.imgObj.contextMenu2.find("> ul.menu.context-menu-image > li.rename");
            deleteButton.off("click");
            deleteButton.on("click", function () {
                t.imgObj.deleteDir(linkDir, t.dom, t);
            })
            renameButton.off("click");
            renameButton.on("click", function () {
                t.imgObj.renameDir(linkDir, t.dom, t, fileName);
            })
            t.imgObj.openContextMenu2();
        });
        t.dom.find('> .name').on('click', function () {
            t.showImage();
        });
        t.wrap.append(t.dom);
        t.f = t.dom.find('> ul');
    },
    removeUI: function () {
        var t = this;
        if (t.dom) {
            t.dom.remove();
        }

    },
    showImage: function () {
        var t = this;
        t.imgObj.namespace = t.namespace;
        t.imgObj.currentDirObj = t;
        if (t.imgObj.activeDirectory && t.imgObj.activeDirectory.dom) {
            t.imgObj.activeDirectory.dom.removeClass("active");
        }
        t.imgObj.activeDirectory = t;
        t.dom.addClass("active");
        if (t.loaded) {
            t.imgObj.l.empty();
            for (var i = 0; i < t.childImg.length; i++) {
                (function () {
                    var linkImage = '/' + t.namespace + '/' + t.childImg[i];
                    var optLink = linhhm.Utilities.resizeImage(linkImage, 100, 100, "webp");
                    var fileName = t.childImg[i];
                    var li = $('<li><div class="img"><img src="" /></div><div class="name"></div></li>');
                    li.find('img').attr('src', linhhm.url(optLink)).attr("data-src", linkImage);
                    li.find('.name').text(t.childImg[i]);
                    li.on('dblclick', function () {
                        var src = $(this).find('img').attr('data-src');
                        t.imgObj.closeDialog();
                        var title = "";
                        var alt = "";
                        if (t.imgObj.flag_title) {
                            title = window.prompt("Tiêu đề hình ảnh");
                            alt = window.prompt("Mô tả hình ảnh");
                        }
                        if (t.imgObj.callback) {
                            if (t.imgObj.callbackContext) t.imgObj.callback.call(t.imgObj.callbackContext, src, title, alt); else
                                t.imgObj.callback(src, title, alt);
                        }
                    });
                    li.on("contextmenu", function (e) {
                        e.originalEvent.preventDefault();
                        var dom = li;
                        var deleteButton = t.imgObj.contextMenu.find("> ul.menu.context-menu-image > li.delete");
                        var renameButton = t.imgObj.contextMenu.find("> ul.menu.context-menu-image > li.rename");
                        var getLinkButton = t.imgObj.contextMenu.find("> ul.menu.context-menu-image > li.getlink");
                        deleteButton.off("click");
                        deleteButton.on("click", function () {
                            t.imgObj.deleteImage(linkImage, dom, t, fileName);
                        })
                        renameButton.off("click");
                        renameButton.on("click", function () {
                            t.imgObj.renameImage(linkImage, dom, t, fileName);
                        })
                        getLinkButton.off("click");
                        getLinkButton.on("click", function () {
                            t.imgObj.getLink(linkImage, dom, t, fileName);
                        })
                        t.imgObj.openContextMenu();
                    })
                    t.imgObj.l.append(li);
                })();
            }
        } else {
            t.loadChild(t.showImage);
        }
    },
    ex: function () {
        var t = this;
        if (t.dom.hasClass('ex')) {
            t.dom.removeClass('ex');
        } else {
            if (!t.loaded) {
                t.loadChild(t.ex);
            } else {
                t.dom.addClass('ex');
            }
        }
    },
    updateNameSpace: function (parentNameSpace) {
        var t = this;
        t.namespace = parentNameSpace + "/" + t.name;
        for (var i in t.childDir) {
            t.childDir[i].updateNameSpace(t.namespace);
        }

    }
});





linhhm.File = function (flagTitle) {
    var t = this;
    t.id = linhhm.Core.randomString(5);
    t.flag_title = true;
    if (typeof flagTitle == "undefined") t.flag_title = true; else
        t.flag_title = !!flagTitle;
    this.$b = $('<div class="lhmImage-box">' +
        '<div class="col-12"><div class="row lhmImage-toolbar">' +
        '<ul class="col s12 col-12">' +
        '<li id="' + t.id + '-new-directory"><span class="fa fa-folder"></span> new</li>' +
        '<li id="' + t.id + '-refresh-2"><span class="fa fa-folder"></span> Refresh content</li>' +
        '<li><input multiple id="' + t.id + '-upload" type="file" style="display: none"><label for="' + t.id + '-upload" class="fa fa-image"> Upload</label></li>' +
        '</ul>' +
        '</div></div>' +
        '<div class="col-12"><div class="row">' +
        '<div class="col s5 col-5 lhmImage-folder"><ul></ul></div>' +
        '<div class="col s7 col-7 lhmImage-list"><ul></ul></div>' +
        '</div></div>' +
        '<div class="context-menu">' +
        '<div class="overlay"></div>' +
        '<ul class="menu context-menu-image">' +
        '<li class="getlink">Lấy link ảnh</li>' +
        '<li class="rename">Đổi tên</li>' +
        '<li class="delete">Xóa</li>' +
        '</ul>' +
        '</div>' +
        '<div class="context-menu-2">' +
        '<div class="overlay"></div>' +
        '<ul class="menu context-menu-image">' +
        '<li class="rename">Đổi tên</li>' +
        '<li class="delete">Xóa</li>' +
        '</ul>' +
        '</div>' +
        '</div>');

    this.b = this.$b[0];
    this.f = this.$b.find('.lhmImage-folder > ul');
    this.l = this.$b.find('.lhmImage-list > ul');
    this.ul = this.$b.find('.lhmImage-toolbar input');
    this.contextMenu = this.$b.find("div.context-menu");
    this.contextMenu2 = this.$b.find("div.context-menu-2");
    this.contextMenu.find("> .overlay").on("click", function () {
        t.closeContextMenu();
    });
    this.contextMenu2.find("> .overlay").on("click", function () {
        t.closeContextMenu2();
    });
    t.namespace = 'data';
    document.body.appendChild(this.b);
    t.activeDirectory = null;
    $("#" + t.id + '-new-directory').on("click", function () {
        if (t.activeDirectory) {
            t.activeDirectory.newDirectory();
        }
    })
    $("#" + t.id + '-refresh-2').on("click", function () {
        if (t.activeDirectory) {
            t.activeDirectory.refresh2();
        }
    })
    this.fileD = new linhhm.FileDirectory(this, null, 'data', 'data', this.f);
    this.fileD.showFile();
    t.currentDirObj = this.fileD;
    this.ul.on('change', function () {
        var file = this.files;
        if (file.length) {
            var form = new FormData();
            var count = 0;
            form.append('path', t.namespace);
            for (var i = 0; i < file.length; i++) {
                if (file[i].type == 'image/jpeg' || file[i].type == 'image/png' || file[i].type == 'image/gif' || file[i].type == 'image/webp') {
                    count++;
                    form.append('file[]', file[i]);
                }
            }
            if (count) {
                linhhm.Core.upload(form, {
                    url: linhhm.url('/aapi/content-edit/ajax-upload-file'),
                    success: function (data) {
                        if (data.status) {
                            t.currentDirObj.loadChild(t.currentDirObj.showFile);
                        }
                    },
                    error: function () {
                        linhhm.UI.notice({ title: "Thông báo", messenger: "Chỉ hỗ trợ hệ màu RGB cho hình" });
                        t.currentDirObj.loadChild(t.currentDirObj.showFile);
                    }

                })
            }
        }
    });
    this.clickOut = function (e) {
        var target = e.target;
        var inNode = false;
        while (target && target != document.body) {
            if (target == t.b) {
                inNode = true;
                break;
            }
            target = target.parentNode;
        }
        if (!inNode) {
            t.closeDialog();
        }
    }
}
linhhm.File.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.File.prototype, {
    loadDirectory: function () {
        this.fileD.loadChild();
    }, setCallback: function (callback, context) {
        this.callback = callback;
        this.callbackContext = context;
    }, openDialog: function () {
        var t = this;
        window.setTimeout(function () {
            t.$b.addClass('active');
            $(document).on('click', t.clickOut);
        }, 200);
    },
    closeDialog: function () {
        this.$b.removeClass('active');
        $(document).off('click', this.clickOut);
    },
    deleteFile: function (link, domUI, dirObj, fileName) {
        var t = this;
        linhhm.Core.upload({ link: link }, {
            url: linhhm.url("/aapi/content-edit/ajax-delete-file"),
            success: function (data) {
                if (data.status) {
                    domUI.remove();
                    for (var i = dirObj.childFile.length - 1; i >= 0; i--) {
                        if (dirObj.childFile[i] == fileName) dirObj.childFile.splice(i, 1);
                    }
                    t.closeContextMenu();
                }
            }, error: function () {
            }
        })
    },
    renameFile: function (link, domUI, dirObj, fileName) {
        var t = this;
        var pt = /^(.+)\.(jpg|png|gif|webp)$/i
        var result = pt.exec(fileName);

        var newName = prompt("Tên tập tin", result[1]);
        if (newName && newName !== result[1]) {
            linhhm.Core.upload({ link: link, newName: newName }, {
                url: linhhm.url("/aapi/content-edit/ajax-rename-file"),
                success: function (data) {
                    if (data.status) {
                        /*domUI.find("img")
                            .attr("src", linhhm.Utilities.resizeImage(data.data.newSrc, 100, 100, "webp"));*/
                        domUI.find("name")
                            .attr("data-src", data.data.newSrc);
                        domUI.find("div.name").text(linhhm.Php.pathinfo(data.data.newSrc).basename);
                        for (var i = dirObj.childFile.length - 1; i >= 0; i--) {
                            if (dirObj.childFile[i] == fileName) dirObj.childFile[i] = data.data.newName;
                        }
                        t.closeContextMenu();
                    }
                },
                error: function () {
                }
            })
        }
    },
    renameDir: function (link, domUI, dirObj, fileName) {
        var t = this;

        var newName = prompt("Tên thư mục", fileName);
        if (dirObj.parent) {
            if (!/^[a-zA-Z0-9-]+$/.exec(newName)) {
                alert("Tên thư mục không dấu, không ' '!");
                return t;
            }
            if (newName && newName !== fileName) {
                linhhm.Core.upload({ link: link, newName: newName }, {
                    url: linhhm.url("/aapi/content-edit/ajax-rename-dir"),
                    success: function (data) {
                        if (data.status) {
                            domUI.find("> div.name").text(newName);
                            dirObj.name = newName;
                            dirObj.updateNameSpace(dirObj.parent.namespace);
                            dirObj.loadChild();
                            t.closeContextMenu2();
                        } else {
                            linhhm.UI.notice(data.error[0]);
                        }
                    },
                    error: function () {
                    }
                })
            }
        } else {
            alert("Không thể đổi tên thư mục gốc");
        }


    },
    deleteDir: function (link, domUI, dirObj) {
        var t = this;
        if (dirObj.parent) {
            if (window.confirm("Xác nhận xóa")) {
                linhhm.Core.upload({ link: link }, {
                    url: linhhm.url("/aapi/content-edit/ajax-delete-dir"),
                    success: function (data) {
                        if (data.status) {
                            dirObj.removeUI();
                            t.closeContextMenu2();
                        } else {
                            linhhm.UI.notice(data.error[0]);
                        }
                    },
                    error: function () {
                    }
                })
            }
        }

    },
    openContextMenu: function () {
        var t = this;
        t.contextMenu.addClass("active");
    }, openContextMenu2: function () {
        var t = this;
        t.contextMenu2.addClass("active");
    }, closeContextMenu: function () {
        var t = this;
        t.contextMenu.removeClass("active");
    }, closeContextMenu2: function () {
        var t = this;
        t.contextMenu2.removeClass("active");
    }
})
linhhm.FileDirectory = function (fileObj, parent, namespace, name, wrap) {
    this.fileObj = fileObj;
    this.parent = parent;
    this.wrap = wrap;
    this.name = name;
    this.namespace = namespace;
    this.childDir = [];
    this.childFile = [];
    this.loaded = false;
    this.buildUI();
}
linhhm.FileDirectory.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.FileDirectory.prototype, {
    loadChild: function (callback) {
        var t = this;
        for (var i in this.childDir) {
            this.childDir[i].removeUI();
        }
        this.childDir.length = 0;
        this.childFile.length = 0;
        linhhm.Core.upload({ directory: t.namespace }, {
            url: linhhm.url("/aapi/content-edit/ajax-get-directory-file-list"),
            success: function (data) {
                if (data.status) {
                    t.loaded = true;
                    for (let i = 0; i < data.data.dir.length; i++) {
                        var newNamespace = t.namespace + '/' + data.data.dir[i];
                        var flagHas = false;
                        for (var y = 0; y < t.childDir.length; y++) {
                            if (t.childDir[y].namespace == newNamespace) {
                                flagHas == true;
                                break;
                            }
                        }
                        if (!flagHas) {
                            t.childDir.push(new linhhm.FileDirectory(t.fileObj, t, newNamespace, data.data.dir[i], t.f));
                        }
                    }
                    for (let i = 0; i < data.data.file.length; i++) {
                        t.childFile.push(data.data.file[i]);
                    }
                    if (callback) callback.call(t);
                }
            }
        });
    },
    loadFileOnly: function (callback) {
        var t = this;
        this.childFile.length = 0;
        linhhm.Core.upload({ directory: t.namespace }, {
            url: linhhm.url("/aapi/content-edit/ajax-get-directory-file-list"),
            success: function (data) {
                if (data.status) {
                    t.loaded = true;
                    for (var i = 0; i < data.data.file.length; i++) {
                        t.childFile.push(data.data.file[i]);
                    }
                    if (callback) callback.call(t);
                }
            }
        });
    },
    newDirectory: function () {
        var t = this;
        var name = prompt("Tên thư mục", "untitled");
        if (/^[a-zA-Z\-0-9]+$/.exec(name) === null) {
            alert("Tên thư mục không dấu, không ' '!");
            return t;
        }
        if (name) {
            linhhm.Core.upload({ name: name, directory: t.namespace }, {
                url: linhhm.url("/aapi/content-edit/ajax-new-directory"),
                success: function (data) {
                    if (data.status) {
                        if (t.loaded == true) {
                            var newNamespace = t.namespace + '/' + data.data.dir;
                            var flagHas = false;
                            for (var y = 0; y < t.childDir.length; y++) {
                                if (t.childDir[y].namespace == newNamespace) {
                                    flagHas == true;
                                    break;
                                }
                            }
                            if (!flagHas) {
                                t.childDir.push(new linhhm.FileDirectory(t.fileObj, t, newNamespace, data.data.dir, t.f));
                            }
                        }
                    }
                }
            })
        }
    },
    refresh2: function () {
        var t = this;
        t.childFile = [];
        t.loadFileOnly(function () {
            t.showFile();
        });
    },
    buildUI: function () {
        var t = this;
        t.dom = $('<li><div class="name"></div><div class="ex"><span class="fa fa-plus"></span><span class="fa fa-minus"></span></div><ul></ul></li>');
        t.dom.find('> .name').html(t.name);
        t.dom.find('> .ex').on('click', function () {
            t.ex();
        });
        t.dom.find('> .name').on('click', function () {
        });
        t.dom.find('> .name').on('contextmenu', function (e) {
            e.originalEvent.preventDefault();

            var linkDir = '/' + t.namespace;
            var fileName = t.name;
            var deleteButton = t.fileObj.contextMenu2.find("> ul.menu.context-menu-image > li.delete");
            var renameButton = t.fileObj.contextMenu2.find("> ul.menu.context-menu-image > li.rename");
            deleteButton.off("click");
            deleteButton.on("click", function () {
                t.fileObj.deleteDir(linkDir, t.dom, t);
            })
            renameButton.off("click");
            renameButton.on("click", function () {
                t.fileObj.renameDir(linkDir, t.dom, t, fileName);
            })
            t.fileObj.openContextMenu2();
        });
        t.dom.find('> .name').on('click', function () {
            t.showFile();
        });
        t.wrap.append(t.dom);
        t.f = t.dom.find('> ul');
    },
    removeUI: function () {
        var t = this;
        if (t.dom) {
            t.dom.remove();
        }

    },
    showFile: function () {
        var t = this;
        t.fileObj.namespace = t.namespace;
        t.fileObj.currentDirObj = t;
        if (t.fileObj.activeDirectory && t.fileObj.activeDirectory.dom) {
            t.fileObj.activeDirectory.dom.removeClass("active");
        }
        t.fileObj.activeDirectory = t;
        t.dom.addClass("active");
        if (t.loaded) {
            t.fileObj.l.empty();
            for (var i = 0; i < t.childFile.length; i++) {
                (function () {
                    var linkFile = '/' + t.namespace + '/' + t.childFile[i];
                    var fileName = t.childFile[i];
                    var li = $('<li style="width: 100%; cursor: pointer;"><div class="name"></div></li>');
                    /*li.find('img').attr('src', optLink).attr("data-src", linkFile);*/
                    li.find('.name').attr("data-src", linkFile);
                    li.find('.name').text(t.childFile[i]);
                    li.on('dblclick', function () {
                        var src = $(this).find('.name').attr('data-src');
                        t.fileObj.closeDialog();
                        if (t.fileObj.callback) {
                            if (t.fileObj.callbackContext)
                                t.fileObj.callback.call(t.fileObj.callbackContext, src);
                            else
                                t.fileObj.callback(src);
                        }
                    });
                    li.on("contextmenu", function (e) {
                        e.originalEvent.preventDefault();
                        var dom = li;
                        var deleteButton = t.fileObj.contextMenu.find("> ul.menu.context-menu-image > li.delete");
                        var renameButton = t.fileObj.contextMenu.find("> ul.menu.context-menu-image > li.rename");
                        var getLinkButton = t.fileObj.contextMenu.find("> ul.menu.context-menu-image > li.getlink");
                        deleteButton.off("click");
                        deleteButton.on("click", function () {
                            t.fileObj.deleteFile(linkFile, dom, t, fileName);
                        })
                        renameButton.off("click");
                        renameButton.on("click", function () {
                            t.fileObj.renameFile(linkFile, dom, t, fileName);
                        })
                        getLinkButton.off("click");
                        getLinkButton.on("click", function () {
                            t.fileObj.getLink(linkFile, dom, t, fileName);
                        })
                        t.fileObj.openContextMenu();
                    })
                    t.fileObj.l.append(li);
                })();
            }
        } else {
            t.loadChild(t.showFile);
        }
    },
    ex: function () {
        var t = this;
        if (t.dom.hasClass('ex')) {
            t.dom.removeClass('ex');
        } else {
            if (!t.loaded) {
                t.loadChild(t.ex);
            } else {
                t.dom.addClass('ex');
            }
        }
    },
    updateNameSpace: function (parentNameSpace) {
        var t = this;
        t.namespace = parentNameSpace + "/" + t.name;
        for (var i in t.childDir) {
            t.childDir[i].updateNameSpace(t.namespace);
        }

    }
});




linhhm.Pdf = function (flagTitle) {
    var t = this;
    t.id = linhhm.Core.randomString(5);
    t.flag_title = true;
    if (typeof flagTitle == "undefined") t.flag_title = true; else
        t.flag_title = !!flagTitle;
    this.$b = $('<div class="lhmImage-box">' + '<div class="row lhmImage-toolbar">' + '<ul class="col s12">' + '<li><input multiple id="' + t.id + '-upload" type="file" style="display: none"><label for="' + t.id + '-upload" class="fa fa-image"> Upload</label></li>' + '</ul>' + '</div>' + '<div class="row">' + '<div class="col s5 lhmImage-folder"><ul></ul></div>' + '<div class="col s7 lhmImage-list"><ul></ul></div>' + '</div>' + '<div class="context-menu">' + '<div class="overlay"></div>' + '<ul class="menu context-menu-image"><li class="getlink">Lấy link ảnh</li><li class="rename">Đổi tên</li><li class="delete">Xóa</li></ul>' + '</div>' + '</div>')
    this.b = this.$b[0];
    this.f = this.$b.find('.lhmImage-folder > ul');
    this.l = this.$b.find('.lhmImage-list > ul');
    this.ul = this.$b.find('.lhmImage-toolbar input');
    this.contextMenu = this.$b.find("div.context-menu");
    this.contextMenu.find("> .overlay").on("click", function () {
        t.closeContextMenu();
    });
    t.namespace = 'pdf';
    document.body.appendChild(this.b);
    t.activeDirectory = null;
    $("#" + t.id + '-new-directory').on("click", function () {
        if (t.activeDirectory) {
            t.activeDirectory.newDirectory();
        }
    })
    this.pdfD = new linhhm.PdfDirectory(this, null, 'pdf', 'pdf', this.f);
    this.pdfD.showPdf();
    t.currentDirObj = this.pdfD;
    this.ul.on('change', function () {
        var file = this.files;
        if (file.length) {
            var form = new FormData();
            var count = 0;
            form.append('path', t.namespace);
            for (var i = 0; i < file.length; i++) {

                if (file[i].type == 'application/pdf') {
                    count++;
                    form.append('image[]', file[i]);
                }
            }
            if (count) {
                linhhm.Core.upload(form, {
                    url: linhhm.url('/aapi/content-edit/ajax-upload-pdf'),
                    success: function (data) {

                        if (data.status) {
                            t.currentDirObj.loadChild(t.currentDirObj.showPdf);
                        }
                    }
                })
            }
        }
    });
    this.clickOut = function (e) {
        var target = e.target;
        var inNode = false;
        while (target && target != document.body) {
            if (target == t.b) {
                inNode = true;
                break;
            }
            target = target.parentNode;
        }
        if (!inNode) {
            t.closeDialog();
        }
    }
}
linhhm.Pdf.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.Pdf.prototype, {
    loadDirectory: function () {
        this.pdfD.loadChild();
    }, setCallback: function (callback, context) {
        this.callback = callback;
        this.callbackContext = context;
    }, openDialog: function () {
        var t = this;
        window.setTimeout(function () {
            t.$b.addClass('active');
            $(document).on('click', t.clickOut);
        }, 200);
    }, closeDialog: function () {
        this.$b.removeClass('active');
        $(document).off('click', this.clickOut);
    }, deletePdf: function (link, domUI, dirObj, fileName) {
        var t = this;
        linhhm.Core.upload({ link: link }, {
            url: linhhm.url("/aapi/content-edit/ajax-delete-pdf"), success: function (data) {
                if (data.status) {
                    domUI.remove();
                    for (var i = dirObj.childPdf.length - 1; i >= 0; i--) {
                        if (dirObj.childPdf[i] == fileName) dirObj.childPdf.splice(i, 1);
                    }
                    t.closeContextMenu();
                }
            }, error: function () {
            }
        })
    }, renamePdf: function (link, domUI, dirObj, fileName) {
        var t = this;
        var pt = /^(.+)\.(pdf)$/i
        var result = pt.exec(fileName);
        var newName = prompt("Tên tập tin", result[1]);
        if (newName && newName !== result[1]) {
            linhhm.Core.upload({ link: link, newName: newName }, {
                url: linhhm.url("/aapi/content-edit/ajax-rename-pdf"),
                success: function (data) {
                    if (data.status) {
                        domUI.find("div.img").data("src", data.data.newSrc);
                        domUI.find("div.name").text(data.data.newName);
                        for (var i = dirObj.childPdf.length - 1; i >= 0; i--) {
                            if (dirObj.childPdf[i] == fileName) dirObj.childPdf[i] = data.data.newName;
                        }
                        t.closeContextMenu();
                    }
                },
                error: function () {
                }
            })
        }
    }, getLink: function () {
        //var newName = prompt("Địa chỉ ảnh", link);
    }, openContextMenu: function () {
        var t = this;
        t.contextMenu.addClass("active");
    }, closeContextMenu: function () {
        var t = this;
        t.contextMenu.removeClass("active");
    }
})
linhhm.PdfDirectory = function (imgObj, parent, namespace, name, wrap) {
    this.imgObj = imgObj;
    this.parent = parent;
    this.wrap = wrap;
    this.name = name;
    this.namespace = namespace;
    this.childDir = [];
    this.childPdf = [];
    this.loaded = false;
    this.buildUI();
}
linhhm.PdfDirectory.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.PdfDirectory.prototype, {
    loadChild: function (callback) {
        var t = this;
        this.childPdf.length = 0;
        linhhm.Core.upload({ directory: t.namespace }, {
            url: linhhm.url("/aapi/content-edit/ajax-get-directory-pdf-list"),
            success: function (data) {
                if (data.status) {
                    t.loaded = true;
                    for (let i = 0; i < data.data.dir.length; i++) {
                        var newNamespace = t.namespace + '/' + data.data.dir[i];
                        var flagHas = false;
                        for (let y = 0; y < t.childDir.length; y++) {
                            if (t.childDir[y].namespace == newNamespace) {
                                flagHas == true;
                                break;
                            }
                        }
                        if (!flagHas) {
                            t.childDir.push(new linhhm.PdfDirectory(t.imgObj, t, newNamespace, data.data.dir[i], t.f));
                        }
                    }
                    for (let i = 0; i < data.data.img.length; i++) {
                        t.childPdf.push(data.data.img[i]);
                    }
                    if (callback) callback.call(t);
                }
            }
        });
    }, newDirectory: function () {
        var t = this;
        var name = prompt("Tên thư mục", "untitled");
        if (name) {
            if (/^[a-z-]+$/i.exec(name) === null) {
                alert("Tên thư mục không dấu, không ' '!");
            } else {
                linhhm.Core.upload({ name: name, directory: t.namespace }, {
                    url: linhhm.url("/aapi/content-edit/ajax-new-directory"),
                    success: function (data) {
                        if (data.status) {
                            if (t.loaded == true) {
                                var newNamespace = t.namespace + '/' + data.data.dir;
                                var flagHas = false;
                                for (var y = 0; y < t.childDir.length; y++) {
                                    if (t.childDir[y].namespace == newNamespace) {
                                        flagHas == true;
                                        break;
                                    }
                                }
                                if (!flagHas) {
                                    t.childDir.push(new linhhm.PdfDirectory(t.imgObj, t, newNamespace, data.data.dir, t.f));
                                }
                            }
                        }
                    }
                })

            }
        }
    }, buildUI: function () {
        var t = this;
        t.dom = $('<li><div class="name"></div><div class="ex"><span class="fa fa-plus"></span><span class="fa fa-minus"></span></div><ul></ul></li>');
        t.dom.find('> .name').html(t.name);
        t.dom.find('> .ex').on('click', function () {
            t.ex();
        });
        t.dom.find('> .name').on('click', function () {
            t.showPdf();
        });
        t.wrap.append(t.dom);
        t.f = t.dom.find('> ul');
    }, showPdf: function () {
        var t = this;
        t.imgObj.namespace = t.namespace;
        t.imgObj.currentDirObj = t;
        if (t.imgObj.activeDirectory && t.imgObj.activeDirectory.dom) {
            t.imgObj.activeDirectory.dom.removeClass("active");
        }

        t.imgObj.activeDirectory = t;
        t.dom.addClass("active");
        if (t.loaded) {
            t.imgObj.l.empty();
            for (var i = 0; i < t.childPdf.length; i++) {
                (function () {
                    var linkPdf = '/' + t.namespace + '/' + t.childPdf[i];
                    var li = $('<li><div class="img"><img src="/public/pdf.png" alt=""></div><div class="name"></div></li>');
                    li.find('div.img').data('src', linkPdf);
                    li.find('.name').text(t.childPdf[i]);
                    li.on('dblclick', function () {
                        var src = $(this).find('div.img').data('src');
                        t.imgObj.closeDialog();
                        if (t.imgObj.callback) {
                            if (t.imgObj.callbackContext) t.imgObj.callback.call(t.imgObj.callbackContext, src); else
                                t.imgObj.callback(src);
                        }
                    });
                    li.on("contextmenu", function (e) {
                        e.originalEvent.preventDefault();
                        var dom = li;
                        var fileName2 = $(this).find('div.img').data("src").replace(/^.*[\\/]/, '');
                        var deleteButton = t.imgObj.contextMenu.find("> ul.menu.context-menu-image > li.delete");
                        var renameButton = t.imgObj.contextMenu.find("> ul.menu.context-menu-image > li.rename");
                        var getLinkButton = t.imgObj.contextMenu.find("> ul.menu.context-menu-image > li.getlink");
                        deleteButton.off("click");
                        deleteButton.on("click", function () {
                            t.imgObj.deletePdf(linkPdf, dom, t, fileName2);
                        })
                        renameButton.off("click");
                        renameButton.on("click", function () {
                            t.imgObj.renamePdf(linkPdf, dom, t, fileName2);
                        })
                        getLinkButton.off("click");
                        getLinkButton.on("click", function () {
                            t.imgObj.getLink(linkPdf, dom, t, fileName2);
                        })
                        t.imgObj.openContextMenu();
                    })
                    t.imgObj.l.append(li);
                })();
            }
        } else {
            t.loadChild(t.showPdf);
        }
    }, ex: function () {
        var t = this;
        if (t.dom.hasClass('ex')) {
            t.dom.removeClass('ex');
        } else {
            if (!t.loaded) {
                t.loadChild(t.ex);
            } else {
                t.dom.addClass('ex');
            }
        }
    }
});
linhhm.BoxSelectImage = function (dom, img) {
    var t = this;
    t.events = [];
    t.img = [];
    t.$el = $(dom);
    t.$el.addClass("lhm-select-images");
    t.$el.html("<ul class='toolbar'><li><span class='fa fa-plus'></span></li></ul><div class='img-wrap'><ul class='image-list'></ul></div>");
    t.inputDisable = t.$el.data("disable");
    if (typeof t.inputDisable == "undefined") {
        t.inputDisable = false;
    } else {
        t.inputDisable = Boolean(t.inputDisable);
    }
    t.ImageBox = new linhhm.Image(false);

    t.ImageBox.setCallback(function (src) {
        t.addImage(src);
    })
    t.inputName = t.$el.data("input-name");
    if (!t.inputDisable) {
        t.$el.find("ul.toolbar > li").on("click", function () {
            t.ImageBox.openDialog();
        })
    }
    if (Array.isArray(img)) {
        for (var i in img) {
            if (img[i].length > 5) {
                t.addImage(img[i]);
            }
        }
    }
}
linhhm.BoxSelectImage.prototype = linhhm.Core.inherit(linhhm.Core.eventAbstract.prototype);
_.extend(linhhm.BoxSelectImage.prototype, {
    on: function (name, cb) {
        this.events.push({ name: name, cb: cb });
    },
    addImage: function (src, tringger = true) {
        var t = this;
        var li = $("<li><input type='hidden' name='" + t.inputName + "[]'><div class='image'><img src=''></div><ul class='action'><li class='prev'><span class='fa fa-caret-left'></span></li><li class='next'><span class='fa fa-caret-right'></span></li><li class='remove'><span class='fa fa-times'></span></li></ul></li>");
        if (t.inputDisable) {
            li.find("input").prop('disabled', true);
        }
        li.find("img").attr("src", linhhm.Utilities.resizeImage(src, 100, 100, "webp"));
        li.find("img").attr("href", linhhm.Utilities.resizeImage(src, 1000, 1000, "webp"));
        li.find("input").val(src);
        li.appendTo(t.$el.find("ul.image-list"));
        if (!t.inputDisable) {
            li.find("> ul.action > li.remove").on("click", function () {
                if (window.confirm("Xóa ảnh này?")) {
                    li.remove();
                    let dt = t.imgData();
                    t.events.forEach(e => {
                        if (e.name == "remove" || e.name == "any") {
                            e.cb(src, dt)
                        }
                    })
                }
            });
            li.find("> ul.action > li.prev").on("click", function () {
                if (this.parentElement.parentElement.previousElementSibling != null) {
                    $(this.parentElement.parentElement).insertBefore(this.parentElement.parentElement.previousElementSibling);
                }
                let dt = t.imgData();
                t.events.forEach(e => {
                    if (e.name == "swap" || e.name == "any") {
                        e.cb(src, dt)
                    }
                })
            });
            li.find("> ul.action > li.next").on("click", function () {
                if (this.parentElement.parentElement.nextElementSibling != null) {
                    $(this.parentElement.parentElement).insertAfter(this.parentElement.parentElement.nextElementSibling);
                }
                let dt = t.imgData();
                t.events.forEach(e => {
                    if (e.name == "swap" || e.name == "any") {
                        e.cb(src, dt)
                    }
                })
            });
        }
        this.img.push(src);
        if (tringger) {
            let dt = t.imgData();
            t.events.forEach(e => {
                if (e.name == "add" || e.name == "any") {
                    e.cb(src, dt)
                }
            })

        }
    },
    imgData: function () {
        let result = [];
        let input = this.$el.find("input");
        let isRemoveDomain = !!window.location;
        let domain = "";
        if (isRemoveDomain) {
            domain = window.location.protocol + "//" + window.location.host;
        }
        for (let i = 0; i < input.length; i++) {
            let src = input[i].value;
            if (isRemoveDomain && src.indexOf(domain) == 0) {
                src = src.substring(domain.length);
            }
            result.push(src);
        }
        return result;
    },
    reloadImgData: function () {
        var t = this;
        t.$el.find("> ul.image-list > li").remove();
        var img = t.$el[0].dataset["img"];
        if (img) {
            img = img.split(",");
            for (var i in img) {
                if (img[i].length > 5) {
                    t.addImage(img[i]);
                }
            }
        }

    }
})

$.fn.BoxSelectImage = function () {
    var arr = [];
    this.each(function () {
        if (!$(this).data('BoxSelectImage')) {
            var tmp = new linhhm.BoxSelectImage(this);
            arr.push(tmp);
            $(this).data('BoxSelectImage', tmp);
        }
    });
    return arr;
}
if (Backbone) {
    Backbone.sync = function (method, model, options) {
        var xhr;
        var uploadConfig = {};
        var data = {};
        switch (method) {
            case 'create': {
                uploadConfig.url = model.urlNew;
            }
                break;
            case 'update': {
                uploadConfig.url = model.urlEdit;
            }
                break;
            case 'delete': {
                uploadConfig.url = model.urlDelete;
            }
                break;
            case 'read': {
                uploadConfig.url = model.urlRead;
            }
                break;
            default:
                throw new Error('unknow method');
        }

        if (options) {
            if (options.success) {
                uploadConfig.success = function () {
                    options.success.apply(model, arguments);
                }
            }
            if (options.error) {
                uploadConfig.error = function () {
                    options.error.apply(model, arguments);
                }
            }
        }
        if (model instanceof Backbone.Collection) {
            if (options.data) {
                data = options.data;
            }
        } else if (model instanceof Backbone.Model) {
            data = model.attributes;
        }
        uploadConfig.linhhmStandard = true;
        let uploadObject = linhhm.Core.upload(data, uploadConfig);
        model.trigger('request', model, xhr, options);
        return uploadObject.getXhr();
    }
}
export default linhhm;
